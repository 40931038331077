import { MaterialType } from '../../../models/materials.models'

import PageMaterialList from '../../../components/page/MaterialList.page'
import { useOutletContext } from 'react-router-dom'
const ListMaterialNeedPage = () => {
  const { useImperials } = useOutletContext<{
    useImperials: boolean
  }>()

  return (
    <PageMaterialList useImperials={useImperials} withTabs canUpdate type={MaterialType.need} />
  )
}
export default ListMaterialNeedPage
