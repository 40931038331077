import { array, boolean, date, number, object, string, mixed } from 'yup'
import I18n from './i18n.utils'
import { ObjectUtils } from './commons.utils'
import { ItemType } from '../models/props.models'

export namespace YupUtils {
  export enum FieldValidationType {
    email,
    password,
    address,
    fullAddress,
    regexp,
    positive,
  }

  export const getRequiredError = (value: any, type: ItemType, props?: any): boolean => {
    try {
      let error: any
      switch (type) {
        case ItemType.address:
        case ItemType.custom:
        case ItemType.asyncSelect:
        case ItemType.addressRadius:
          error = object().nullable().required(I18n.t('errors:required'))
          break
        case ItemType.checkbox:
          error = boolean()
            .nullable()
            .isTrue(I18n.t('errors:required'))
            .required(I18n.t('errors:required'))
          break
        case ItemType.date:
          error = date().nullable().required(I18n.t('errors:required'))
          break
        case ItemType.documents:
          error = array()
            .nullable()
            .min(1, I18n.t('errors:required'))
            .required(I18n.t('errors:required'))
          if (props?.documents?.length > 0) {
            error = null
          }
          break
        case ItemType.file:
          error = mixed().required(I18n.t('errors:required'))
          break
        case ItemType.image:
          if (!props.fileDetails) {
            error = mixed().nullable().required(I18n.t('errors:required'))
          }
          break
        case ItemType.interval:
          error = array()
            .nullable()
            .min(2, I18n.t('errors:required'))
            .max(2, I18n.t('errors:required'))
            .required(I18n.t('errors:required'))
          break
        case ItemType.array:
        case ItemType.multiSelect:
          error = array()
            .nullable()
            .min(1, I18n.t('errors:required'))
            .required(I18n.t('errors:required'))
          break
        case ItemType.number:
          error = number().nullable().required(I18n.t('errors:required'))
          break
        case ItemType.radio:
          if (props?.multiple) {
            error = array()
              .nullable()
              .min(1, I18n.t('errors:required'))
              .required(I18n.t('errors:required'))
          } else {
            error = string().nullable().required(I18n.t('errors:required'))
          }
          break
        case ItemType.search:
        case ItemType.select:
        case ItemType.text:
          error = string().nullable().required(I18n.t('errors:required'))
          break
      }
      error?.validateSync(value)
      return false
    } catch (err: any) {
      // return err.message if we want to show error
      return true
    }
  }

  export const getFieldError = (value: any, validationType: FieldValidationType, params?: any) => {
    try {
      let error: any
      switch (validationType) {
        case FieldValidationType.positive:
          error = number().nullable().min(0, I18n.t('errors:positive'))
          break
        case FieldValidationType.email:
          error = string().nullable().email(I18n.t('errors:invalidFormat'))
          break
        case FieldValidationType.regexp:
          error = !value
            ? null
            : string().nullable().matches(new RegExp(params), I18n.t('errors:invalidFormat'))
          break
        case FieldValidationType.password:
          error = !value
            ? null
            : string()
                .nullable()
                .min(8, I18n.t('errors:password'))
                .test(
                  'oneUppercase',
                  I18n.t('errors:password'),
                  (value) => !!value && /[A-Z]/.test(value),
                )
                .test(
                  'oneLowercase',
                  I18n.t('errors:password'),
                  (value) => !!value && /[a-z]/.test(value),
                )
                .test(
                  'oneNumber',
                  I18n.t('errors:password'),
                  (value) => !!value && /[0-9]/.test(value),
                )
          break

        case FieldValidationType.fullAddress:
          error = !value
            ? null
            : object({
                fullAddress: string().nullable().required(I18n.t('errors:required')),
                position: array()
                  .nullable()
                  .required(I18n.t('errors:required'))
                  .length(2, I18n.t('errors:required')),
                city: string().nullable().required(I18n.t('errors:required')),
                postalCode: string().nullable(),
              })
                .nullable()
                .required(I18n.t('errors:required'))
          break
      }
      error?.validateSync(value)
    } catch (err: any) {
      return err.message
    }
  }
  const checkRules = (key: string, rules: [(value: any) => string], value: any) => {
    if (rules) {
      return rules.reduce(
        (
          acc: string,
          rule:
            | { rule: FieldValidationType; params: any }
            | FieldValidationType
            | ((value: any) => string | undefined),
        ) => {
          let error
          if (typeof rule === 'number') {
            error = getFieldError(ObjectUtils.getKeyValue(value, key), rule as FieldValidationType)
          } else if (typeof rule === 'function') {
            error = (rule as (value: any) => string)(value) || ''
          } else {
            const ruleWithParams = rule as { rule: FieldValidationType; params: any }
            error = getFieldError(
              ObjectUtils.getKeyValue(value, key),
              ruleWithParams.rule as FieldValidationType,
              ruleWithParams.params,
            )
          }
          return acc || error
        },
        '',
      )
    }
    return undefined
  }

  export const getError = (items: any[], value: any): Record<string, string> => {
    const errors: Record<string, any> = {}
    items
      .map((item) => (typeof item === 'function' ? item(value) : item))
      .forEach(({ hideItem, key, rules, required, props, type, items: subItems }) => {
        if (hideItem) {
          return
        }

        if (type === ItemType.group) {
          errors[key] = getError(subItems, value)
          errors[key].global = checkRules(key, rules, value)
        } else if (type === ItemType.array) {
          const arrayValue = ObjectUtils.getKeyValue(value, key)
          errors[key] = {}
          errors[key].global = required && getRequiredError(arrayValue, type, props)
          if (arrayValue && arrayValue.length) {
            for (let i = 0; i < arrayValue.length; i++) {
              errors[key][i] = getError(subItems, arrayValue[i])
            }
          }
        } else {
          errors[key] =
            required && getRequiredError(ObjectUtils.getKeyValue(value, key), type, props)
          if (!errors[key]) {
            errors[key] = checkRules(key, rules, value)
          }
        }
      })

    return errors
  }
  export const checkIfErrors: any = (errors: Object) => {
    return Object.values(errors).some((err) => {
      return typeof err === 'string' || err === true || typeof err === 'undefined'
        ? !!err
        : checkIfErrors(err)
    })
  }
}
