import { forwardRef, FormEvent, useState, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  DialogActions,
  styled,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'

import { YupUtils } from '../../utils/yup.utils'
import { FormItem, ItemType } from '../../models/props.models'
import useSnackbar from '../../hooks/useSnackbar.hooks'

import Stack from './Stack.common'
import Loader from './Loader.common'
import ArrayItem from './item/Array.item'
import Stepper from './item/Stepper.item'
import Tabs from './Tabs.common'

const Summary = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  borderRight: `1px solid ${theme.palette.lightBorder}`,
  backgroundColor: theme.palette.white,
  minWidth: '200px',
  width: '200px',
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
  paddingTop: '15px',
  paddingBottom: '15px',
  marginRight: '15px',
  paddingRight: '15px',
}))

const Container = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'scrollable' && prop !== 'headerHeight' && prop !== 'summaryHeight',
})<{ scrollable?: boolean; headerHeight?: number; summaryHeight?: number }>(
  ({ scrollable, headerHeight, summaryHeight }) => ({
    maxHeight: scrollable ? `calc(90vh - ${200 + (headerHeight ?? 0)}px)` : undefined,
    minHeight: `${summaryHeight ?? 0}px`,
    overflow: scrollable ? 'hidden auto' : undefined,
  }),
)

export type FormRef = { loading: boolean }

export type FormProps = {
  value: Record<string, any>
  setValue: React.Dispatch<React.SetStateAction<any>>
  items?: FormItem[]
  tabs?: { title: string; items: FormItem[] }[]
  steps?: { title: string; items: FormItem[] }[]
  submitLabel?: string
  formatValue?: (value: any) => any
  onSubmit: (value: any) => any | Promise<any>
  onSuccess?: (formValue: any) => void | Promise<void>
  onDelete?: () => void | Promise<void>
  noPadding?: boolean
  scrollable?: boolean
  summary?: boolean
  header?: JSX.Element
  fullwidth?: boolean
}

const Form = forwardRef<FormRef, FormProps>(
  (
    {
      value,
      steps,
      tabs,
      items,
      submitLabel,
      onSubmit,
      formatValue,
      setValue,
      onSuccess,
      onDelete,
      noPadding,
      scrollable,
      fullwidth,
      header,
      summary,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const show = useSnackbar()
    const theme = useTheme()
    const downMd = useMediaQuery(theme.breakpoints.down('md'))

    const headerContainerRef = useRef<HTMLElement>(null)
    const [loading, setLoading] = useState(false)
    useImperativeHandle(ref, () => ({
      loading,
    }))

    const [step, setStep] = useState(0)

    const handleOnDelete = async () => {
      setLoading(true)
      try {
        await onDelete?.()
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    }

    const handleSubmit = async (event: FormEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (steps && step < steps.length - 1) {
        setStep((state) => state + 1)
      } else {
        setLoading(true)
        const formatedValue = formatValue ? formatValue(value) : value
        try {
          const result = await onSubmit(formatedValue)
          await onSuccess?.(result)
        } catch (err: any) {
          show(err)
        }
        setLoading(false)
      }
    }

    const hasSummary = scrollable && items && summary && !downMd
    const summaryContainerRef = useRef<HTMLElement>(null)
    const containerTopRef = useRef<HTMLElement>(null)
    const summaryItems: { label: string; key: string }[] = useMemo(() => {
      const findSummary = (items: FormItem[]) => {
        return items?.reduce((summaryItems: { label: string; key: string }[], item) => {
          const computedItem = typeof item === 'function' ? item(value) : item
          if (computedItem.summary && !computedItem.hideItem) {
            summaryItems.push({ key: computedItem.key, label: computedItem.summary })
          }
          if (computedItem.type === ItemType.group && computedItem.items?.length > 0) {
            summaryItems = summaryItems.concat(findSummary(computedItem.items as FormItem[]))
          }
          return summaryItems
        }, [])
      }
      return items ? findSummary(items) : []
    }, [value, items])
    const [currentSummary, setCurrentSummary] = useState(summaryItems[0]?.key)
    const [isGoingTo, setIsGoingTo] = useState(false)
    const goToSummary = ({ label, key }: { label: string; key: string }) => {
      setIsGoingTo(true)
      document.getElementById(label)?.scrollIntoView({ behavior: 'smooth' })
      setCurrentSummary(key)
      setTimeout(() => {
        setIsGoingTo(false)
      }, 1500)
    }
    const onScroll = () => {
      if (hasSummary && !isGoingTo && containerTopRef.current) {
        // we change current when the next item 100px from top
        const top = containerTopRef.current?.getBoundingClientRect()?.top + 100
        let i
        for (i = 0; i < summaryItems.length - 1; i++) {
          if (
            (document.getElementById(summaryItems[i + 1].label)?.getBoundingClientRect()?.top ??
              0) -
              top >
            0
          ) {
            setCurrentSummary(summaryItems[i].key)
            break
          }
        }

        if (i === summaryItems.length - 1) {
          setCurrentSummary(summaryItems[i].key)
        }
      }
    }

    const errors: Record<string, any> = useMemo(() => {
      const currentItems = items || steps?.[step].items || tabs?.[step].items || []
      return tabs
        ? YupUtils.getError(
            tabs.reduce(
              (acc, tab: { title: string; items: FormItem[] }) => acc.concat(tab.items),
              [] as FormItem[],
            ),
            value,
          )
        : YupUtils.getError(currentItems, value)
    }, [tabs, items, steps, step, value])

    return (
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={noPadding ? '' : '15px'}
        mx="auto"
        component="form"
        onSubmit={handleSubmit}
        maxWidth={fullwidth ? 'none' : '800px'}
        width="100%"
        overflow="hidden"
        flexGrow="1">
        {header && (
          <Box ref={headerContainerRef} width="100%">
            {header}
          </Box>
        )}
        <Box display="flex" width="100%" position="relative" ref={containerTopRef}>
          {hasSummary && (
            <Summary ref={summaryContainerRef}>
              {summaryItems.map((summaryItem) => (
                <Box
                  display="flex"
                  key={summaryItem.key}
                  onClick={() => goToSummary(summaryItem)}
                  sx={{ cursor: 'pointer' }}>
                  <Typography
                    color={currentSummary === summaryItem.key ? 'primary' : undefined}
                    sx={{
                      marginBottom: '10px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                      fontWeight: currentSummary === summaryItem.key ? 500 : undefined,
                    }}>
                    {summaryItem.label}
                  </Typography>
                </Box>
              ))}
            </Summary>
          )}
          <Container
            scrollable={scrollable}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            pt={header ? '15px' : undefined}
            pb="30px"
            px="5px"
            spacing={4}
            headerHeight={headerContainerRef.current?.clientHeight}
            summaryHeight={summaryContainerRef.current?.clientHeight}
            onScroll={onScroll}>
            {items && (
              <Grid container spacing={2}>
                <ArrayItem value={value} errors={errors} items={items} setValue={setValue} />
              </Grid>
            )}
            {steps && (
              <Stepper
                progress={((step + 1) / steps.length) * 100}
                title={steps[step].title}
                error={YupUtils.checkIfErrors(errors)}>
                <ArrayItem
                  value={value}
                  errors={errors}
                  items={steps[step].items}
                  setValue={setValue}
                />
              </Stepper>
            )}
            {tabs && (
              <Stack spacing={2} width="100%">
                <Tabs
                  items={tabs.map((tab) => ({ label: tab.title }))}
                  onChange={(value) => setStep(value)}
                  value={step}
                  hasError={(index) =>
                    YupUtils.checkIfErrors(YupUtils.getError(tabs[index].items, value))
                  }
                  sx={{ padding: 0 }}
                />
                <Grid container spacing={2}>
                  <ArrayItem
                    value={value}
                    errors={errors}
                    items={tabs[step].items}
                    setValue={setValue}
                  />
                </Grid>
              </Stack>
            )}
          </Container>
        </Box>

        <DialogActions>
          {steps && step > 0 && (
            <Stack direction="row" spacing="20px">
              <Button
                variant="contained"
                size="large"
                color="primary"
                disabled={loading}
                fullWidth
                onClick={() => setStep((step) => step - 1)}>
                <ChevronLeftIcon fontSize="medium" />
              </Button>
            </Stack>
          )}
          {onDelete && (
            <Box maxWidth="270px">
              <Button
                variant="contained"
                size="large"
                color="error"
                fullWidth
                disabled={loading}
                onClick={handleOnDelete}>
                {t('global:actions.delete')}
                {loading && (
                  <Box position="absolute" right="5px" top="15px">
                    <Loader />
                  </Box>
                )}
              </Button>
            </Box>
          )}
          <Box width={!onDelete ? '270px' : 'auto'} maxWidth="270px">
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit"
              disabled={YupUtils.checkIfErrors(errors) || loading}>
              {steps && step < steps.length - 1 && t('global:actions.next')}
              {steps && step === steps.length - 1 && (submitLabel || t('global:actions.finish'))}
              {(items || tabs) && (submitLabel || t('global:actions.save'))}
              {loading && (
                <Box position="absolute" right="5px" top="15px">
                  <Loader />
                </Box>
              )}
            </Button>
          </Box>
        </DialogActions>
      </Stack>
    )
  },
)
export default Form
