import React, { useState } from 'react'
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import Loader from '../common/Loader.common'

import useSnackbar from '../../hooks/useSnackbar.hooks'

const ModalContent = styled(Box, { shouldForwardProp: (key) => key !== 'noContentPadding' })<{
  noContentPadding?: boolean
}>(({ noContentPadding, theme }) => ({
  padding: noContentPadding ? '0' : '30px 50px',
  [theme.breakpoints.down('md')]: {
    padding: noContentPadding ? '0' : '10px 20px',
  },
  [theme.breakpoints.only('xs')]: {
    padding: noContentPadding ? '0' : '10px',
  },
}))

const Title = styled(Typography)({ lineHeight: 1.25, textAlign: 'center' })
const Description = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.25,
  textAlign: 'center',
  whiteSpace: 'pre-line',
})

interface ModalProps {
  title?: string
  description?: string
  children?: JSX.Element | JSX.Element[]
  loadingRef?: any
  permanent?: boolean
  noContentPadding?: boolean
  hideAction?: boolean
  whiteClose?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  keepOpen?: boolean
  onClose: () => void
  closeLabel?: string
  onSuccess?: (data?: any) => void | Promise<void>
  confirmLabel?: string
  onConfirm?: () => Promise<any>
  noTitleMargin?: boolean
  disabled?: boolean
}
const Modal: React.FC<ModalProps> = ({
  title,
  maxWidth,
  noContentPadding,
  description,
  children,
  loadingRef,
  permanent,
  closeLabel,
  onClose,
  confirmLabel,
  onConfirm,
  onSuccess,
  hideAction,
  whiteClose,
  keepOpen,
  disabled,
  noTitleMargin,
}): JSX.Element => {
  const { t } = useTranslation()
  const show = useSnackbar()

  const [loading, setLoading] = useState(false)
  const launchConfirm = async () => {
    if (onConfirm) {
      setLoading(true)
      try {
        const res = await onConfirm()
        await onSuccess?.(res)
        if (!keepOpen) {
          onClose()
        }
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    }
  }
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'xs'}
      open
      onClose={() => !loading && !loadingRef?.current?.loading && !permanent && onClose()}>
      <Box position="absolute" right="0" top="0" zIndex="10">
        <IconButton onClick={() => !loading && !loadingRef?.current?.loading && onClose()}>
          <CloseIcon sx={{ color: whiteClose ? 'white' : 'inherit' }} />
        </IconButton>
      </Box>
      <ModalContent
        noContentPadding={noContentPadding}
        width="100%"
        display="flex"
        flexDirection="column"
        flexGrow="1">
        {title && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={noTitleMargin ? '0' : '30px'}>
            <Title variant="h2">{title}</Title>
          </Box>
        )}
        {description && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            pb={hideAction && !children ? '0' : '30px'}>
            <Description>{description}</Description>
          </Box>
        )}
        {children && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={hideAction ? '0' : '30px'}>
            {children}
          </Box>
        )}
        {!hideAction && (
          <DialogActions>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  disabled={loading}
                  onClick={onClose}>
                  {closeLabel || t('global:actions.close')}
                </Button>
              </Grid>

              {onConfirm && (
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    disabled={loading || disabled}
                    onClick={launchConfirm}>
                    {confirmLabel || t('global:actions.confirm')}
                    {loading && (
                      <Box position="absolute" right="5px" top="15px">
                        <Loader />
                      </Box>
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        )}
      </ModalContent>
    </Dialog>
  )
}
export default Modal
