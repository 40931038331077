import { useState, useMemo, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'

import {
  ManageMaterial,
  MaterialType,
  Unit,
  MaterialQuality,
  TermsOfSale,
  MaterialState,
  convert2Meters,
  ImperialUnit,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { RetrievalModality, Currency } from '../../models/commons.models'
import { sessionService } from '../../store/session'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import InputCategory from '../category/Input.category'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  type: MaterialType
  useImperials: boolean
}
const ModalFormMaterial: React.FC<ModalFormMaterialProps> = (props) => {
  const { t } = useTranslation()
  const { useImperials, onSubmit, type, ...formProps } = props
  const [value, setValue] = useState<Partial<ManageMaterial>>({
    type,
  } as ManageMaterial)
  // it won't update
  const isNeedRef = useRef(value?.type === MaterialType.need)
  const showPublicRef = useRef(sessionService.showPublic(value?.type as MaterialType))
  const submitFormMany = useCallback(
    (value: any) => {
      onSubmit?.(useImperials ? convert2Meters(value) : value)
    },
    [onSubmit, useImperials],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'global',
        props: {
          title: t('materials:components.modalForm.global'),
        },
        grid: { xs: 12 },
        items: [
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            grid: { xs: 12, md: 6 },
            custom: (
              <InputCategory
                label={t('materials:attributes.category')}
                placeholder={t('global:inputs.notModified')}
                accurateCategory
                primaryCategory={value.primaryCategory}
                secondaryCategory={value.secondaryCategory}
                tertiaryCategory={value.tertiaryCategory}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'initialQty',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.desiredQty'),
              placeholder: t('global:inputs.notModified'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, md: 3 },
            key: 'unit',
            props: {
              label: t('materials:attributes.unit'),
              placeholder: t('global:inputs.notModified'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.date,
            grid: { xs: 6, md: 3 },
            key: 'retrieval.endDate',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.needsEndDate'),
              placeholder: t('global:inputs.notModified'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, md: 3 },
            key: 'quality',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.minimumQuality'),
              placeholder: t('global:inputs.notModified'),
              items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, md: 3 },
            key: 'uniqueDeposit',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.uniqueDeposit'),
              falseValue: true,
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, md: 3 },
            key: 'visible',
            hideItem: !showPublicRef.current,
            props: {
              label: t('materials:attributes.visible'),
              falseValue: true,
            },
          },
        ] as FormItem[],
      },
      {
        type: ItemType.group,
        key: 'sell',
        hideItem: isNeedRef.current,
        props: {
          title: t('materials:attributes.sell.title'),
        },
        items: [
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 3 },
            key: 'sellByQuantityOf',
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.sellByQuantityOf'),
              placeholder: t('global:inputs.notModified'),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 3 },
            key: 'minQuantity',
            rules: [
              (value) =>
                !value.sellByQuantityOf ||
                !value.minQuantity ||
                value.minQuantity % value.sellByQuantityOf === 0
                  ? ''
                  : t('errors:multipleOf', { value: value.sellByQuantityOf }),
            ],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.minQuantity'),
              placeholder: t('global:inputs.notModified'),
              step: material.sellByQuantityOf ?? 1,
            },
          }),
          {
            type: ItemType.select,
            grid: { xs: 12, sm: 6, md: 3 },
            key: 'termsOfSale',
            props: {
              label: t('materials:attributes.sell.termsOfSale'),
              placeholder: t('global:inputs.notModified'),
              items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 3 },
            hideItem: material.termsOfSale !== TermsOfSale.sale,
            key: 'price',
            rules: [YupUtils.FieldValidationType.positive],
            required: material.termsOfSale === TermsOfSale.sale,
            props: {
              label: t('materials:attributes.sell.price'),
              placeholder: t('materials:attributes.sell.price'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`global:currency.${sessionService.getCountryParam('currency') as Currency}`)}/
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
            },
          }),
        ] as FormItem[],
      },
      {
        type: ItemType.group,
        key: 'retrieval',
        hideItem: isNeedRef.current,
        props: {
          title: t('materials:attributes.retrieval.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 12, sm: 6 },
            key: 'state',
            props: {
              label: t('materials:attributes.state'),
              placeholder: t('global:inputs.notModified'),
              items: createOptionsFromEnum(MaterialState, 'materials:state'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 12, md: 6 },
            key: 'retrieval.fromDefault',
            props: {
              falseValue: true,
              label: t('materials:attributes.retrieval.fromDefault'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 12, sm: 6 },
            hideItem: material?.retrieval?.fromDefault !== false,
            key: 'retrieval.startDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              placeholder: t('global:inputs.notModified'),
              label: t('materials:attributes.retrieval.startDate'),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 12, sm: 6 },
            key: 'retrieval.endDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              placeholder: t('global:inputs.notModified'),
              label: t('materials:attributes.retrieval.endDate'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          (material: ManageMaterial) => ({
            type: ItemType.select,
            grid: { xs: 12, sm: 6 },
            key: 'retrieval.retrievalModality',
            props: {
              label: t('materials:attributes.retrieval.modality'),
              placeholder: t('global:inputs.notModified'),
              items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          {
            type: ItemType.text,
            key: 'conditioning',
            props: {
              multiline: true,
              minRows: 2,
              label: t('materials:attributes.conditioning'),
              placeholder: t('global:inputs.notModified'),
            },
          },
        ] as FormItem[],
      },
    ],
    [t, value.primaryCategory, value.secondaryCategory, value.tertiaryCategory, useImperials],
  )

  return (
    <ModalForm
      {...formProps}
      value={value}
      setValue={setValue}
      items={items}
      maxWidth="lg"
      onSubmit={submitFormMany}
    />
  )
}
export default ModalFormMaterial
