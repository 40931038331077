import { Point, Pagination, Retrieval, Currency } from './commons.models'
import { Category } from './categories.models'
import { CatalogLight, Room } from './catalogs.models'
import { FileDetails } from './files.models'
import { PlanUtils } from '../utils/plan.utils'

export enum Unit {
  set = 'set',
  linearMeters = 'lm',
  squareMeters = 'm2',
  cubicMeters = 'm3',
  unitary = 'u',
  tone = 't',
}
export enum ImperialUnit {
  set = 'set',
  linearFoot = 'lft',
  squareFoot = 'ft2',
  cubicFoot = 'ft3',
  unitary = 'u',
  tone = 't',
}
export enum DimensionUnit {
  millimeters = 'mm',
  centimeters = 'cm',
  meters = 'm',
}
export enum ImperialDimensionUnit {
  inch = 'in',
  foot = 'ft',
  yard = 'yd',
}

export enum QuantityType {
  inventory = 'inventory',
  order = 'order',
  theft = 'theft',
  breakage = 'breakage',
  others = 'others',
}

export enum MaterialQuality {
  new = 'new',
  good = 'good',
  slightlyDamaged = 'slightlyDamaged',
  damaged = 'damaged',
}
export enum MaterialState {
  awaitingDeposit = 'awaitingDeposit',
  deposited = 'deposited',
}

export enum TermsOfSale {
  donation = 'donation',
  sale = 'sale',
  notDefined = 'notDefined',
}

export enum MaterialType {
  resource = 'resource',
  need = 'need',
}

export interface MaterialQuantity {
  _id: string
  initial: boolean
  type: QuantityType
  quantity: number
  linkToRoom?: boolean
  quality?: MaterialQuality
  description?: string
  order?: string
  plan?: { _id: string; name?: string }
  room?: { _id: string; name?: string }
  position?: Point
}
export const convert2Imperials = (material: any): any => {
  if (!material) {
    return material
  }
  const unitFactor =
    material.unit === Unit.linearMeters
      ? 3.28084
      : material.unit === Unit.squareMeters
      ? 3.28084 * 3.28084
      : material.unit === Unit.cubicMeters
      ? 3.28084 * 3.28084 * 3.28084
      : 1
  const dimensionUnitFactor =
    material.dimensions?.unit === DimensionUnit.millimeters
      ? 25.4
      : material.dimensions?.unit === DimensionUnit.centimeters
      ? 30.48
      : material.dimensions?.unit === DimensionUnit.meters
      ? 0.9144
      : 1

  return {
    ...material,
    unit:
      material.unit === Unit.linearMeters
        ? ImperialUnit.linearFoot
        : material.unit === Unit.squareMeters
        ? ImperialUnit.squareFoot
        : material.unit === Unit.cubicMeters
        ? ImperialUnit.cubicFoot
        : material.unit,
    dimensions: material.dimensions
      ? {
          unit:
            material.dimensions.unit === DimensionUnit.millimeters
              ? ImperialDimensionUnit.inch
              : material.dimensions.unit === DimensionUnit.centimeters
              ? ImperialDimensionUnit.foot
              : material.dimensions.unit === DimensionUnit.meters
              ? ImperialDimensionUnit.yard
              : material.dimensions.unit,
          length: material.dimensions.length
            ? material.dimensions.length / dimensionUnitFactor
            : material.dimensions.length,
          width: material.dimensions.width
            ? material.dimensions.width / dimensionUnitFactor
            : material.dimensions.width,
          height: material.dimensions.height
            ? material.dimensions.height / dimensionUnitFactor
            : material.dimensions.height,
          diameter: material.dimensions.diameter
            ? material.dimensions.diameter / dimensionUnitFactor
            : material.dimensions.diameter,
        }
      : material.dimensions,
    unitWeight: material.unitWeight ? material.unitWeight / unitFactor : material.unitWeight,
    price: material.price ? material.price / unitFactor : material.price,
    sellByQuantityOf: material.sellByQuantityOf
      ? material.sellByQuantityOf * unitFactor
      : material.sellByQuantityOf,
    minQuantity: material.minQuantity ? material.minQuantity * unitFactor : material.minQuantity,
    quantities: material.quantities
      ? material.quantities.map((q: MaterialQuantity) => ({
          ...q,
          quantity: q.quantity * unitFactor,
        }))
      : material.quantities,
    initialQty: material.initialQty ? material.initialQty * unitFactor : material.initialQty,
    currentQty: material.currentQty ? material.currentQty * unitFactor : material.currentQty,
    reservedQty: material.reservedQty ? material.reservedQty * unitFactor : material.reservedQty,
  }
}
export const convert2Meters = (material: any): any => {
  if (!material) {
    return material
  }
  const unitFactor =
    material.unit === ImperialUnit.linearFoot
      ? 3.28084
      : material.unit === ImperialUnit.squareFoot
      ? 3.28084 * 3.28084
      : material.unit === ImperialUnit.cubicFoot
      ? 3.28084 * 3.28084 * 3.28084
      : 1
  const dimensionUnitFactor =
    material.dimensions?.unit === ImperialDimensionUnit.inch
      ? 25.4
      : material.dimensions?.unit === ImperialDimensionUnit.foot
      ? 30.48
      : material.dimensions?.unit === ImperialDimensionUnit.yard
      ? 0.9144
      : 1

  return {
    ...material,
    unit:
      material.unit === ImperialUnit.linearFoot
        ? Unit.linearMeters
        : material.unit === ImperialUnit.squareFoot
        ? Unit.squareMeters
        : material.unit === ImperialUnit.cubicFoot
        ? Unit.cubicMeters
        : material.unit,
    dimensions: material.dimensions
      ? {
          unit:
            material.dimensions.unit === ImperialDimensionUnit.inch
              ? DimensionUnit.millimeters
              : material.dimensions.unit === ImperialDimensionUnit.foot
              ? DimensionUnit.centimeters
              : material.dimensions.unit === ImperialDimensionUnit.yard
              ? DimensionUnit.meters
              : material.dimensions.unit,
          length: material.dimensions.length
            ? material.dimensions.length * dimensionUnitFactor
            : material.dimensions.length,
          width: material.dimensions.width
            ? material.dimensions.width * dimensionUnitFactor
            : material.dimensions.width,
          height: material.dimensions.height
            ? material.dimensions.height * dimensionUnitFactor
            : material.dimensions.height,
          diameter: material.dimensions.diameter
            ? material.dimensions.diameter * dimensionUnitFactor
            : material.dimensions.diameter,
        }
      : material.dimensions,
    unitWeight: material.unitWeight ? material.unitWeight * unitFactor : material.unitWeight,
    price: material.price ? material.price * unitFactor : material.price,
    sellByQuantityOf: material.sellByQuantityOf
      ? material.sellByQuantityOf / unitFactor
      : material.sellByQuantityOf,
    minQuantity: material.minQuantity ? material.minQuantity / unitFactor : material.minQuantity,
    quantities: material.quantities
      ? material.quantities.map((q: MaterialQuantity) => ({
          ...q,
          quantity: q.quantity / unitFactor,
        }))
      : material.quantities,
    initialQty: material.initialQty ? material.initialQty / unitFactor : material.initialQty,
    currentQty: material.currentQty ? material.currentQty / unitFactor : material.currentQty,
    reservedQty: material.reservedQty ? material.reservedQty / unitFactor : material.reservedQty,
  }
}

export const computeResourceQuantities = (
  materialQuantities: MaterialQuantity[],
): {
  quantities: MaterialQuantity[]
  quality: MaterialQuality
  initialQty: number
  currentQty: number
} => {
  let quality = MaterialQuality.slightlyDamaged
  const initialQty =
    materialQuantities
      ?.filter((materialQuantity) => materialQuantity.initial !== false)
      ?.reduce((total: number, materialQuantitiy) => {
        const value = Number(materialQuantitiy.quantity)
        if (Number.isNaN(value)) {
          return total
        }
        return total + value
      }, 0) || 0
  const currentQty =
    initialQty +
      materialQuantities
        ?.filter((materialQuantity) => materialQuantity.initial === false)
        ?.reduce((total: number, materialQuantitiy) => {
          const value = Number(materialQuantitiy.quantity)
          if (Number.isNaN(value)) {
            return total
          }
          return total + value
        }, 0) || 0

  const totalQuantityWithQualitity =
    materialQuantities?.reduce(
      (acc, materialQuantity) =>
        acc +
        (materialQuantity.quality && materialQuantity.quantity ? materialQuantity.quantity : 0),
      0,
    ) || 1
  const qualityWeight =
    materialQuantities?.reduce((acc: number, materialQuantity) => {
      return (
        acc +
        (materialQuantity.quality && materialQuantity.quantity
          ? materialQuantity.quantity *
            (materialQuantity.quality === MaterialQuality.new
              ? 4
              : materialQuantity.quality === MaterialQuality.good
              ? 3
              : materialQuantity.quality === MaterialQuality.slightlyDamaged
              ? 2
              : 1)
          : 0)
      )
    }, 0) || 0
  const averageQuantity = Math.round(qualityWeight / totalQuantityWithQualitity)
  if (averageQuantity !== 0) {
    quality =
      averageQuantity === 4
        ? MaterialQuality.new
        : averageQuantity === 3
        ? MaterialQuality.good
        : averageQuantity === 2
        ? MaterialQuality.slightlyDamaged
        : MaterialQuality.damaged
  }
  return {
    quality,
    initialQty,
    currentQty,
    quantities: materialQuantities,
  }
}

export const getMaterialQuantityLabel = (materialQuantity: MaterialQuantity) => {
  if (!!materialQuantity) {
    if (materialQuantity.plan && materialQuantity.room && materialQuantity.room.name) {
      return `${materialQuantity.plan.name} : ${materialQuantity.room.name}`
    } else if (materialQuantity.plan) {
      return materialQuantity.plan.name
    } else {
      return materialQuantity?.description || ''
    }
  }
  return ''
}

export const isLinkToRoomDimension = (material?: Material): boolean => {
  return material?.unit === Unit.linearMeters || material?.unit === Unit.squareMeters
}
export const getRoomDimension = (
  planScale: number,
  material?: Material,
  room?: Room,
): number | undefined => {
  if (room) {
    switch (material?.unit) {
      case Unit.linearMeters:
        return Number((PlanUtils.getPerimeter(room.points) * planScale).toFixed(2))
      case Unit.squareMeters:
        return Number((PlanUtils.getArea(room.points) * planScale * planScale).toFixed(2))
      default:
    }
  }
  return
}

export interface LocatedMaterial {
  _id: string
  linkToRoom?: boolean
  material: string
  plan: string
  position: Point
  quantity: number
  quality: MaterialQuality
  primaryCategory: Category
  secondaryCategory: Category
  tertiaryCategory: Category
  room?: string
}

export interface ManageMaterial extends Omit<Material, 'catalog'> {
  catalog: string
  mainImageFileFile?: File
  imageFilesFile?: File[]
  filesFile?: File[]
}
export interface Material {
  createdAt?: Date
  _id: string
  name: string
  visible: boolean
  catalog: CatalogLight
  originalCatalog: CatalogLight
  type: MaterialType
  reference: string
  primaryCategory: Category
  secondaryCategory: Category
  tertiaryCategory: Category
  quantities: MaterialQuantity[]
  initialQty: number
  currentQty: number
  reservedQty: number
  unit: Unit
  sellByQuantityOf: number
  minQuantity: number
  termsOfSale: TermsOfSale
  price: number | null
  currency: Currency
  unitWeight: number
  density: number
  state: MaterialState
  retrieval: Retrieval
  uniqueDeposit: boolean
  conditioning: string
  description?: string
  privateDescription?: string
  quality: MaterialQuality
  dimensions: {
    unit: DimensionUnit
    length?: number
    width?: number
    height?: number
    diameter?: number
  }
  technicalDetails: { value: string; name: string }[]
  carbonTotal: number
  tracksOfReuse: string
  mainImageFile: FileDetails
  imageFiles: FileDetails[]
  files: FileDetails[]
}

export interface PlanLocatedMaterial extends LocatedMaterial {
  isTmp?: boolean
  isSelected?: boolean
  isMaterialSelected?: boolean
}

interface ApiMaterialQuantity extends Omit<MaterialQuantity, '_id' | 'plan' | 'room'> {
  _id?: string
  plan?: string
  room?: string
}
export interface ApiMaterial
  extends Omit<
    Material,
    | 'catalog'
    | 'primaryCategory'
    | 'secondaryCategory'
    | 'tertiaryCategory'
    | 'mainImageFile'
    | 'files'
    | 'imageFiles'
    | 'quantities'
    | '_id'
  > {
  _id?: string
  catalog: string
  primaryCategory: string
  secondaryCategory: string
  tertiaryCategory: string
  mainImageFile: string
  files: string[]
  imageFiles: string[]
  quantities: ApiMaterialQuantity[]
}

export interface ExportedMaterial extends Omit<ApiMaterial, 'catalog'> {}

export interface MaterialsPagination extends Pagination {
  data: Material[]
}
