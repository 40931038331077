import { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import { MaterialModel } from '../../models/materialModels.models'
import {
  Unit,
  DimensionUnit,
  ImperialUnit,
  ImperialDimensionUnit,
  convert2Imperials,
  convert2Meters,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { organizationsService } from '../../store/organizations'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import InputCategory from '../category/Input.category'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialModelProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  materialModel?: MaterialModel
  useImperials: boolean
}
const ModalFormMaterialModel: React.FC<ModalFormMaterialModelProps> = (props) => {
  const { t } = useTranslation()
  const { useImperials, materialModel, onSubmit, ...formProps } = props
  const [value, setValue] = useState<Partial<MaterialModel>>(
    useImperials
      ? convert2Imperials(materialModel ?? ({} as MaterialModel))
      : materialModel ?? ({} as MaterialModel),
  )
  const submitMaterialModel = useCallback(
    (value: MaterialModel) => {
      onSubmit?.(useImperials ? convert2Meters(value) : value)
    },
    [onSubmit, useImperials],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'general',
        items: [
          {
            type: ItemType.text,
            grid: { xs: 12, md: 4 },
            key: 'name',
            required: true,
            props: {
              label: t('materialModels:attributes.name'),
              placeholder: t('materialModels:attributes.name'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, md: 4 },
            key: 'unit',
            required: true,
            props: {
              label: t('materialModels:attributes.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.asyncSelect,
            key: 'organization',
            grid: { xs: 6, md: 4 },
            required: true,
            formatValue: (organization: any) => {
              return organization
                ? { label: organization.name ?? '', value: organization._id ?? '' }
                : undefined
            },
            props: {
              label: t('materialModels:attributes.organization'),
              placeholder: t('materialModels:attributes.organization'),
              minimumSearchLength: 3,
              getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
              dataValue: true,
            },
          },
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            required: true,
            custom: (
              <InputCategory
                label={t('materialModels:attributes.category')}
                placeholder={t('materialModels:attributes.category')}
                required
                accurateCategory
                primaryCategory={value.primaryCategory}
                secondaryCategory={value.secondaryCategory}
                tertiaryCategory={value.tertiaryCategory}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          (materialModel: MaterialModel) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'unitWeight',
            props: {
              label: t('materials:attributes.weight.unit'),
              placeholder: t('materials:attributes.weight.unit'),
              endAdornment: materialModel?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.${materialModel.unit}`),
                  })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'density',
            props: {
              label: t('materials:attributes.density'),
              placeholder: t('materials:attributes.density'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.m3`),
                  })}
                </EndAdornmentLabel>
              ),
            },
          },
        ],
      },
      (materialModel: MaterialModel) => ({
        type: ItemType.group,
        key: 'dimensionsGroup',
        props: {
          title: t('materialModels:attributes.dimensions.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.unit',
            props: {
              label: t('materialModels:attributes.dimensions.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(
                useImperials ? ImperialDimensionUnit : DimensionUnit,
                'materials:dimensionUnit',
              ),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.length',
            props: {
              label: t('materialModels:attributes.dimensions.length'),
              placeholder: t('materialModels:attributes.dimensions.length'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.width',
            props: {
              label: t('materialModels:attributes.dimensions.width'),
              placeholder: t('materialModels:attributes.dimensions.width'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.height',
            props: {
              label: t('materialModels:attributes.dimensions.height'),
              placeholder: t('materialModels:attributes.dimensions.height'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.diameter',
            props: {
              label: t('materialModels:attributes.dimensions.diameter'),
              placeholder: t('materialModels:attributes.dimensions.diameter'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
        ],
      }),
      {
        type: ItemType.group,
        key: 'technicalDetailsGroup',
        props: {
          title: t('materialModels:attributes.technicalDetails.title'),
        },
        items: [
          {
            type: ItemType.array,
            key: 'technicalDetails',
            props: {
              orderable: true,
              addLabel: t('materialModels:attributes.technicalDetails.addButton'),
            },
            items: [
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'name',
                required: true,
                props: {
                  label: t('materialModels:attributes.technicalDetails.name'),
                  placeholder: t('materialModels:attributes.technicalDetails.name'),
                },
              },
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'value',
                props: {
                  label: t('materialModels:attributes.technicalDetails.value'),
                  placeholder: t('materialModels:attributes.technicalDetails.value'),
                },
              },
            ] as FormItem[],
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'carbon',
        props: {
          title: t('materialModels:attributes.carbon.title'),
        },
        items: [
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 4, md: 3 },
            key: 'carbonTotal',
            props: {
              label: t('materialModels:attributes.carbon.carbonTotal'),
              placeholder: t('materialModels:attributes.carbon.carbonTotalPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2/UF</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.text,
            grid: { xs: 12, sm: 8, md: 9 },
            key: 'tracksOfReuse',
            props: {
              label: t('materialModels:attributes.carbon.tracksOfReuse'),
              placeholder: t('materialModels:attributes.carbon.tracksOfReuse'),
            },
          },
        ],
      },
    ],
    [t, useImperials, value.primaryCategory, value.secondaryCategory, value.tertiaryCategory],
  )

  return (
    <ModalForm
      {...formProps}
      value={value}
      setValue={setValue}
      items={items}
      maxWidth="lg"
      onSubmit={submitMaterialModel}
    />
  )
}
export default ModalFormMaterialModel
