import { alpha } from '@mui/material'
export const createButton = (colors: any) => ({
  MuiButton: {
    root: {
      textTransform: 'inherit',
    },
    contained: {
      height: '45px',
      '&[aria-label=error]': {
        '&$containedSizeLarge': {
          height: '50px',
        },
        backgroundColor: colors.error,
        borderRadius: 0,
        color: colors.white,
        height: '45px',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.25,
      },
      '&[aria-label=success]': {
        '&$containedSizeLarge': {
          height: '50px',
        },
        backgroundColor: colors.success,
        borderRadius: 0,
        color: colors.white,
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.25,
      },
      '&[aria-label=purple]': {
        '&$containedSizeLarge': {
          height: '50px',
        },
        backgroundColor: colors.purple,
        borderRadius: 0,
        color: colors.white,
        height: '45px',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.25,
      },
    },
    containedSuccess: {
      backgroundColor: colors.success,
      borderRadius: 0,
      color: colors.white,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    containedSizeLarge: {
      fontSize: '1rem',
      height: '50px',
    },
    containedPrimary: {
      borderRadius: 0,
      color: colors.white,
      fontSize: '0.875rem',
      fontWeight: 600,
      padding: '0 17px',
      '&.Mui-disabled': {
        backgroundColor: colors.primary,
        color: alpha(colors.white, 0.5),
      },
    },
    containedSecondary: {
      borderRadius: 0,
      color: colors.white,
      fontSize: '1rem',
      fontWeight: 600,
      padding: '0 17px',
      '&.Mui-disabled': {
        backgroundColor: colors.secondary,
        color: alpha(colors.white, 0.5),
      },
      '&[aria-label=favorite]': {
        backgroundColor: alpha(colors.primary, 0.1),
        flexShrink: 0,
        minWidth: 'auto',
        padding: '0',
        width: '45px !important',
      },
    },
    textSizeSmall: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
})
