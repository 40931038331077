import { Pagination, Location, Currency } from './commons.models'
import { FileDetails } from './files.models'
import { PlatformReference } from './platforms.models'

export enum OrganizationStatus {
  pending = 'pending',
  accepted = 'accepted',
  deleted = 'deleted',
}

export enum LegalForm {
  asso = 'asso',
  ei = 'ei',
  eurl = 'eurl',
  sarl = 'sarl',
  sa = 'sa',
  sas = 'sas',
  sasu = 'sasu',
  snc = 'snc',
  scop = 'scop',
  sca = 'sca',
  scs = 'scs',
  epa = 'epa',
  epic = 'epic',
  scic = 'scic',
  sem = 'sem',
  spl = 'spl',
  epci = 'epci',
  sm = 'sm',
  other = 'other',
}

export interface ManageOrganization extends Omit<Partial<Organization>, 'kbis'> {
  kbis?: File | FileDetails
}

export interface OrganizationsPagination extends Pagination {
  data: Organization[]
}

export interface Organization {
  _id: string
  platform: PlatformReference
  name: string
  siret: string
  location: Location
  currency: Currency
  legalForm?: LegalForm
  kbis?: FileDetails
}
export interface ApiOrganization extends Omit<ManageOrganization, 'kbis'> {
  kbis?: string
}
