import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

import frLocale from 'dayjs/locale/fr.js'
import frCaLocale from 'dayjs/locale/fr-ca.js'
import EnLocale from 'dayjs/locale/en.js'


dayjs.locale(frLocale)
dayjs.extend(localeData)

export const changeLanguage = (lang: string) => {
  if (lang === 'fr-CA') {
    dayjs.locale(frCaLocale)
  } else if (lang === 'en') {
    dayjs.locale(EnLocale)
  } else {
    dayjs.locale(frCaLocale)
  }
}

export default dayjs
