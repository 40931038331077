import sessionsTranslations from './sessions.frCa.json'
import catalogsTranslations from './catalogs.frCa.json'
import categoriesTranslations from './categories.frCa.json'
import organizationsTranslations from './organizations.frCa.json'
import errorsTranslations from './errors.frCa.json'
import globalTranslations from './global.frCa.json'
import materialModelsTranslations from './materialModels.frCa.json'
import materialsTranslations from './materials.frCa.json'
import ordersTranslations from './orders.frCa.json'
import usersTranslations from './users.frCa.json'
import requestsTranslations from './requests.frCa.json'

const FRCA = {
  catalogs: catalogsTranslations,
  categories: categoriesTranslations,
  organizations: organizationsTranslations,
  errors: errorsTranslations,
  global: globalTranslations,
  materialModels: materialModelsTranslations,
  materials: materialsTranslations,
  orders: ordersTranslations,
  users: usersTranslations,
  requests: requestsTranslations,
  sessions: sessionsTranslations,
}

export default FRCA
