import { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Switch, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useObservable } from '@ngneat/react-rxjs'

import {
  ManageMaterial,
  MaterialType,
  Unit,
  MaterialQuality,
  DimensionUnit,
  TermsOfSale,
  MaterialState,
  computeResourceQuantities,
  MaterialQuantity,
  QuantityType,
  convert2Imperials,
  convert2Meters,
  ImperialUnit,
  ImperialDimensionUnit,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { RetrievalModality, Route, Currency } from '../../models/commons.models'
import { sessionService } from '../../store/session'
import { materialModelsQuery } from '../../store/materialModels'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { ObjectUtils } from '../../utils/commons.utils'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import MaterialTableQuantities from './TableQuantities.material'
import Constants from '../../constants'
import useRoute from '../../hooks/useRoute.hooks'
import InputCategory from '../category/Input.category'
import LinkButton from '../common/button/Link.button'
import { CatalogLight } from '../../models/catalogs.models'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

type UpdatedQuantities = {
  initialQty: number
  currentQty: number
  quality: MaterialQuality
  type: QuantityType
  description?: string
}
interface ModalFormMaterialProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs' | 'onDelete'> {
  material: Partial<ManageMaterial>
  onDelete?: (materialId: string) => void
  linkedCatalog?: CatalogLight
  useImperials: boolean
}
const ModalFormMaterial: React.FC<ModalFormMaterialProps> = (props) => {
  const { useImperials, linkedCatalog, material, onDelete, onSubmit, ...formProps } = props
  const { t } = useTranslation()
  const { goTo } = useRoute()
  const theme = useTheme()
  const onlyXl = useMediaQuery(theme.breakpoints.only('xl'))

  const [value, setValue] = useState<ManageMaterial>(
    (useImperials
      ? convert2Imperials({
          sellByQuantityOf: 1,
          minQuantity: 0,
          ...material,
        })
      : {
          sellByQuantityOf: 1,
          minQuantity: 0,
          ...material,
        }) as ManageMaterial,
  )

  // update resource quantity
  const [modal, setModal] = useState(false)
  const [updatedQuantities, setUpdatedQuantities] = useState<UpdatedQuantities>({
    ...computeResourceQuantities(value.quantities ?? []),
    type: QuantityType.inventory,
    description: undefined,
  })
  const updateQuantities = useCallback((updatedQuantities: UpdatedQuantities) => {
    setValue((value) => {
      let res = [...value.quantities]
      const { initialQty } = computeResourceQuantities(res)
      if (updatedQuantities.initialQty !== initialQty) {
        res.push({
          _id: `local${Math.random().toString().split('.')[1]}`,
          type: updatedQuantities.type,
          initial: true,
          quantity: updatedQuantities.initialQty - initialQty,
          quality: updatedQuantities.quality,
          description: updatedQuantities.description,
          linkToRoom: false,
        })
      }

      const { currentQty } = computeResourceQuantities(res)
      if (updatedQuantities.currentQty !== currentQty) {
        res.push({
          _id: `local${Math.random().toString().split('.')[1]}`,
          type: updatedQuantities.type,
          initial: false,
          quantity: updatedQuantities.currentQty - currentQty,
          quality: updatedQuantities.quality,
          description: updatedQuantities.description,
          linkToRoom: false,
        })
      }

      const { quality } = computeResourceQuantities(res)
      // We can not keep separate quantities...
      if (quality !== updatedQuantities.quality) {
        res = res.map((q: MaterialQuantity) => ({ ...q, quality: updatedQuantities.quality }))
      }
      return {
        ...value,
        ...computeResourceQuantities(res),
      }
    })

    setUpdatedQuantities({
      initialQty: updatedQuantities.initialQty,
      currentQty: updatedQuantities.currentQty,
      quality: updatedQuantities.quality,
      type: QuantityType.inventory,
      description: undefined,
    })
  }, [])

  // it won't update
  const isCreationRef = useRef(!value?._id)
  const isNeedRef = useRef(value?.type === MaterialType.need)
  const showPublicRef = useRef(sessionService.showPublic(value?.type))
  const disableQuantityDirectUpdateRef = useRef(!isNeedRef.current && !isCreationRef.current)

  const [materialModels] = useObservable(materialModelsQuery.materialModels)
  const materialModelOptions = useMemo(
    () =>
      materialModels.map((materialModel) => ({
        label: `${materialModel.name} (${t(
          `categories:name.${materialModel.primaryCategory.name}` as any,
        )})`,
        value: materialModel._id,
        data: materialModel,
      })),
    [t, materialModels],
  )

  useEffect(() => {
    if (value.price) {
      setValue((currentValue) => ({ ...currentValue, termsOfSale: TermsOfSale.sale }))
    }
  }, [value.price])
  useEffect(() => {
    if (value.termsOfSale !== TermsOfSale.sale) {
      setValue((currentValue) => ({ ...currentValue, price: null }))
    }
  }, [value.termsOfSale])

  const handleSetValue = useCallback(
    (updateValue: (currentValue: ManageMaterial) => ManageMaterial) => {
      setValue((currentValue) => {
        const val = updateValue(currentValue)

        if ((val as any)?.materialModel) {
          const { _id, ...materialModelData } = ObjectUtils.copy((val as any).materialModel)
          return {
            ...val,
            ...(useImperials ? convert2Imperials(materialModelData) : materialModelData),
            materialModel: undefined,
          }
        }

        return val
      })
    },
    [useImperials],
  )
  const onMaterialSubmit = useCallback(
    async (value: ManageMaterial): Promise<any> => {
      const converted = useImperials ? convert2Meters(value) : value

      if (isCreationRef.current && !isNeedRef.current) {
        const quantities: MaterialQuantity[] = []
        if (converted.initialQty > 0) {
          quantities.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            initial: true,
            quality: converted.quality,
            type: QuantityType.inventory,
            quantity: converted.initialQty,
          })
        }
        if (converted.initialQty !== converted.currentQty) {
          quantities.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            initial: false,
            quality: converted.quality,
            type: QuantityType.inventory,
            quantity: converted.currentQty - converted.initialQty,
          })
        }
        return await onSubmit({
          ...converted,
          ...computeResourceQuantities(quantities),
        })
      }

      return await onSubmit(converted)
    },
    [onSubmit, useImperials],
  )

  const commonItems: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.asyncSelect,
        key: 'materialModel',
        hideItem: !isCreationRef.current || materialModelOptions.length === 0,
        props: {
          label: t('materials:components.modalForm.materialModel'),
          searchIfEmpty: true,
          placeholder: t('global:inputs.selectPlaceholder'),
          dataValue: '_id',
          dataLabel: 'name',
          options: materialModelOptions,
          tooltip: t('materials:components.modalForm.materialModelTooltip'),
        },
      },
      {
        type: ItemType.group,
        grid: { xs: 12, sm: 6 },
        key: 'left',
        items: [
          {
            type: ItemType.text,
            key: 'name',
            required: true,
            props: {
              label: t('materials:attributes.name'),
              placeholder: t('materials:attributes.name'),
            },
          },
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            required: true,
            custom: (
              <InputCategory
                label={t('materials:attributes.category')}
                placeholder={t('materials:attributes.category')}
                required
                accurateCategory
                primaryCategory={value.primaryCategory}
                secondaryCategory={value.secondaryCategory}
                tertiaryCategory={value.tertiaryCategory}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          {
            type: ItemType.select,
            key: 'unit',
            required: true,
            grid: { xs: 6, xl: 3 },
            props: {
              label: t('materials:attributes.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, xl: 3 },
            key: 'quality',
            props: {
              onClick: disableQuantityDirectUpdateRef.current
                ? setModal.bind(null, true)
                : undefined,
              readOnly: disableQuantityDirectUpdateRef.current,
              label: isNeedRef.current
                ? t('materials:attributes.minimumQuality')
                : t('materials:attributes.globalQuality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            key: 'initialQty',
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              onClick: disableQuantityDirectUpdateRef.current
                ? setModal.bind(null, true)
                : undefined,
              readOnly: disableQuantityDirectUpdateRef.current,
              label: isNeedRef.current
                ? t('materials:attributes.desiredQty')
                : t('materials:attributes.quantities.initial'),
              placeholder: isNeedRef.current
                ? t('materials:attributes.desiredQty')
                : t('materials:attributes.quantities.initial'),
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
            },
          }),
          // only resources
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, xl: 3 },
            key: 'currentQty',
            hideItem: isNeedRef.current,
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              onClick: disableQuantityDirectUpdateRef.current
                ? setModal.bind(null, true)
                : undefined,
              readOnly: disableQuantityDirectUpdateRef.current,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.current'),
              placeholder: t('materials:attributes.quantities.current'),
            },
          }),
          {
            type: ItemType.group,
            key: 'dimensionsGroup',
            hideItem: isNeedRef.current,
            summary: t('materials:attributes.dimensions.title'),
            items: [
              {
                type: ItemType.typo,
                key: 'dimensionsTitle',
                grid: { xs: 8, sm: 9 },
                formatValue: () => t('materials:attributes.dimensions.title'),
                props: {
                  variant: 'h3',
                },
              },
              {
                type: ItemType.select,
                grid: { xs: 4, sm: 3 },
                key: 'dimensions.unit',
                props: {
                  label: t('materials:attributes.dimensions.unit'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(
                    useImperials ? ImperialDimensionUnit : DimensionUnit,
                    'materials:dimensionUnit',
                  ),
                },
              },
            ],
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.length',
            props: {
              label: t('materials:attributes.dimensions.length'),
              placeholder: t('materials:attributes.dimensions.length'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.width',
            props: {
              label: t('materials:attributes.dimensions.width'),
              placeholder: t('materials:attributes.dimensions.width'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.height',
            props: {
              label: t('materials:attributes.dimensions.height'),
              placeholder: t('materials:attributes.dimensions.height'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.diameter',
            props: {
              label: t('materials:attributes.dimensions.diameter'),
              placeholder: t('materials:attributes.dimensions.diameter'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          // only need
          {
            type: ItemType.date,
            grid: { xs: 6, xl: 3 },
            key: 'retrieval.endDate',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.needsEndDate'),
            },
          },
          {
            type: ItemType.checkbox,
            key: 'uniqueDeposit',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.uniqueDeposit'),
            },
          },
          {
            type: ItemType.text,
            key: 'description',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.observations'),
              multiline: true,
              minRows: 2,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        grid: { xs: 12, sm: 6 },
        key: 'right',
        items: [
          (material: ManageMaterial) => ({
            type: ItemType.image,
            key: 'mainImageFileFile',
            required: !isNeedRef.current,
            props: {
              height: onlyXl ? '256px' : '248px',
              label: t('materials:components.modalForm.addPicture'),
              maxFileSize: 2.5,
              fileDetails: material.mainImageFile,
            },
          }),
          (material: ManageMaterial) => ({
            key: 'imageFilesFile',
            type: ItemType.documents,
            grid: { xs: 12, xl: 6 },
            props: {
              cardSize: 80,
              type: 'image',
              documents: material.imageFiles,
              label: t('materials:attributes.imageFiles'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  imageFiles: (state.imageFiles ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: 2.5,
            },
          }),
          (material: ManageMaterial) => ({
            key: 'filesFile',
            type: ItemType.documents,
            grid: { xs: 12, xl: 6 },
            props: {
              cardSize: 80,
              type: 'file',
              documents: material.files,
              label: t('materials:attributes.files'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).filter((_, fIndex) => fIndex !== index),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  files: (state.files ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: 2.5,
            },
          }),
        ],
      },
    ],
    [
      t,
      onlyXl,
      materialModelOptions,
      value.primaryCategory,
      value.secondaryCategory,
      value.tertiaryCategory,
      useImperials,
    ],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'global',
        summary: t('materials:components.modalForm.global'),
        props: {
          title: t('materials:components.modalForm.global'),
        },
        grid: { xs: 12 },
        items: commonItems,
      },
      {
        type: ItemType.group,
        key: 'sell',
        summary: t('materials:attributes.sell.title'),
        props: {
          title: t('materials:attributes.sell.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 3 },
            key: 'termsOfSale',
            props: {
              label: t('materials:attributes.sell.termsOfSale'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'price',
            rules: [YupUtils.FieldValidationType.positive],
            required: material.termsOfSale === TermsOfSale.sale,
            props: {
              label: t('materials:attributes.sell.price'),
              placeholder: t('materials:attributes.sell.price'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(
                    `global:currency.${
                      material?.currency || (sessionService.getCountryParam('currency') as Currency)
                    }`,
                  )}
                  /{material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'sellByQuantityOf',
            required: true,
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.sellByQuantityOf'),
              placeholder: t('materials:attributes.sell.sellByQuantityOf'),
              min: 0,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'minQuantity',
            rules: [
              YupUtils.FieldValidationType.positive,
              (value) =>
                !value.sellByQuantityOf ||
                !value.minQuantity ||
                value.minQuantity % value.sellByQuantityOf === 0
                  ? ''
                  : t('errors:multipleOf', {
                      value: value.sellByQuantityOf,
                    }),
            ],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.minQuantity'),
              placeholder: t('materials:attributes.sell.minQuantity'),
              step: material.sellByQuantityOf ?? 1,
              min: 0,
            },
          }),
        ] as FormItem[],
      },
      {
        type: ItemType.group,
        key: 'observationsGroup',
        summary: t('materials:attributes.observations'),
        props: {
          title: t('materials:attributes.observations'),
        },
        items: [
          {
            type: ItemType.text,
            key: 'description',
            grid: { xs: 12, lg: 6 },
            props: {
              tooltip: t('materials:attributes.descriptionTooltip'),
              label: t('materials:attributes.description'),
              multiline: true,
              minRows: 2,
            },
          },
          {
            type: ItemType.text,
            key: 'privateDescription',
            grid: { xs: 12, lg: 6 },
            props: {
              tooltip: t('materials:attributes.privateDescriptionTooltip'),
              label: t('materials:attributes.privateDescription'),
              multiline: true,
              minRows: 2,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'technicalDetailsGroup',
        summary: t('materials:attributes.technicalDetails.title'),
        props: {
          title: t('materials:attributes.technicalDetails.title'),
        },
        items: [
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'unitWeight',
            props: {
              label: t('materials:attributes.weight.unit'),
              placeholder: t('materials:attributes.weight.unit'),
              endAdornment: material?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.${material.unit}`),
                  })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'totalWeight',
            formatValue: () => (material.unitWeight ?? 0) * (material.initialQty ?? 0),
            props: {
              label: t('materials:attributes.weight.total'),
              placeholder: t('materials:attributes.weight.total'),
              readOnly: true,
              endAdornment: material?.unit ? (
                <EndAdornmentLabel>
                  {t('materials:attributes.weight.kg', { value: '' })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'density',
            props: {
              label: t('materials:attributes.density'),
              placeholder: t('materials:attributes.density'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.m3`),
                  })}
                </EndAdornmentLabel>
              ),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'totalVolume',
            formatValue: () =>
              !material.density || !material.unitWeight || !material.initialQty
                ? 0
                : (material.unitWeight * material.initialQty) / material.density,
            props: {
              label: t('materials:attributes.volume'),
              placeholder: t('materials:attributes.volume'),
              readOnly: true,
              endAdornment: <EndAdornmentLabel>{t(`materials:unitSymbol.m3`)}</EndAdornmentLabel>,
            },
          }),
          {
            type: ItemType.text,
            grid: { xs: 12, sm: 6 },
            key: 'tracksOfReuse',
            props: {
              label: t('materials:attributes.carbon.tracksOfReuse'),
              placeholder: t('materials:attributes.carbon.tracksOfReuse'),
            },
          },

          {
            type: ItemType.array,
            key: 'technicalDetails',
            props: {
              orderable: true,
              addLabel: t('materials:attributes.technicalDetails.addButton'),
            },
            items: [
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'name',
                required: true,
                props: {
                  label: t('materials:attributes.technicalDetails.name'),
                  placeholder: t('materials:attributes.technicalDetails.name'),
                },
              },
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'value',
                props: {
                  label: t('materials:attributes.technicalDetails.value'),
                  placeholder: t('materials:attributes.technicalDetails.value'),
                },
              },
            ] as FormItem[],
          },
        ],
      },

      {
        type: ItemType.group,
        key: 'retrieval',
        summary: t('materials:attributes.retrieval.title'),
        props: {
          title: t('materials:attributes.retrieval.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 4 },
            key: 'state',
            props: {
              label: t('materials:attributes.state'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialState, 'materials:state'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, sm: 4 },
            key: 'retrieval.fromDefault',
            formatValue: (fromDefault) => fromDefault !== false,
            props: {
              label: t('materials:attributes.retrieval.fromDefault'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.select,
            grid: { xs: 12, sm: 4 },
            key: 'retrieval.retrievalModality',
            props: {
              label: t('materials:attributes.retrieval.modality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 6, sm: 4 },
            hideItem: material?.retrieval?.fromDefault !== false,
            key: 'retrieval.startDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              label: t('materials:attributes.retrieval.startDate'),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 6, sm: 4 },
            key: 'retrieval.endDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              label: t('materials:attributes.retrieval.endDate'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          {
            type: ItemType.text,
            key: 'conditioning',
            props: {
              multiline: true,
              minRows: 2,
              label: t('materials:attributes.conditioning'),
              placeholder: t('materials:attributes.conditioning'),
            },
          },
        ] as FormItem[],
      },
      (material: ManageMaterial) => ({
        type: ItemType.group,
        key: 'quantities',
        summary: t('materials:components.modalForm.quantities'),
        props: {
          title: t('materials:components.modalForm.quantities'),
        },
        hideItem: !disableQuantityDirectUpdateRef.current,
        grid: { xs: 12 },
        rules: [
          (material: ManageMaterial) =>
            material.initialQty < 0 || material.currentQty < 0 ? t('errors:quantityNegative') : '',
        ],
        items: [
          {
            type: ItemType.custom,
            key: 'quantities',
            custom: (
              <MaterialTableQuantities
                quantities={material.quantities}
                unit={material.unit}
                setValue={setValue}
              />
            ),
          },
          {
            type: ItemType.typo,
            key: 'total',
            grid: { xs: 12 },
            formatValue: () => t('materials:components.modalForm.totalQuantity'),
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'initialQty',
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.initial'),
              placeholder: t('materials:attributes.quantities.initial'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'currentQty',
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.current'),
              placeholder: t('materials:attributes.quantities.current'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'reservedQty',
            hideItem: Constants.getIsLocal(),
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.reserved'),
              placeholder: t('materials:attributes.quantities.reserved'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 3 },
            key: 'quality',
            props: {
              readOnly: true,
              label: t('materials:attributes.globalQuality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
            },
          },
        ],
      }),
      {
        type: ItemType.group,
        key: 'carbon',
        summary: t('materials:attributes.carbon.title'),
        props: {
          title: t('materials:attributes.carbon.title'),
        },
        items: [
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'carbonTotal',
            props: {
              label: t('materials:attributes.carbon.carbonTotal'),
              placeholder: t('materials:attributes.carbon.carbonTotalPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2/UF</EndAdornmentLabel>,
            },
          },
        ],
      },
    ],
    [t, commonItems],
  )

  return (
    <>
      <ModalForm
        {...formProps}
        summary={!isNeedRef.current}
        onDelete={material._id && onDelete ? () => onDelete(material._id as string) : undefined}
        value={value}
        setValue={handleSetValue}
        items={isNeedRef.current ? commonItems : items}
        onSubmit={onMaterialSubmit}
        maxWidth="xl"
        header={
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            width="100%"
            padding="15px 0"
            borderBottom={`1px solid ${Constants.colors.lightBorder}`}>
            {!isCreationRef.current && (
              <Box display="flex" sx={{ marginRight: '20px' }}>
                <Typography variant="h3" sx={{ paddingTop: '7px' }}>
                  {t('materials:attributes.reference')}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: Constants.colors.textFieldBackground,
                    borderRadius: '4px',
                    padding: '5px 10px',
                    height: 'fit-content',
                    marginLeft: '5px',
                  }}>
                  <Typography>{material.reference}</Typography>
                </Box>
              </Box>
            )}
            {showPublicRef.current && (
              <Box display="flex" sx={{ marginRight: '20px' }} alignItems="center">
                <Switch
                  onChange={(_, checked) => setValue((val) => ({ ...val, visible: checked }))}
                  checked={value.visible !== false}
                />
                <Typography
                  sx={{
                    marginLeft: '5px',
                  }}>
                  {t('materials:attributes.visible')}
                </Typography>
              </Box>
            )}
            {linkedCatalog && (
              <LinkButton
                onClick={() => {
                  goTo({
                    route: Route.workspaceCatalog,
                    catalogId: linkedCatalog._id,
                  })
                }}
                sx={{ marginRight: '20px' }}>
                {t('materials:components.modalForm.linkedCatalog', {
                  catalog: linkedCatalog.name,
                })}
              </LinkButton>
            )}
            {material.originalCatalog && (
              <LinkButton
                onClick={() => {
                  if (material?.originalCatalog?._id) {
                    goTo({
                      route: Route.workspaceCatalog,
                      catalogId: material.originalCatalog._id,
                    })
                  }
                }}
                sx={{ marginRight: '20px' }}>
                {t('materials:components.modalForm.originalCatalog', {
                  catalog: material?.originalCatalog?.name,
                })}
              </LinkButton>
            )}
          </Box>
        }
      />
      {modal && (
        <ModalForm
          title={t('materials:components.modalForm.updateQuantities')}
          submitLabel={t('global:actions.validate')}
          maxWidth="md"
          items={[
            {
              type: ItemType.number,
              grid: { xs: 6, md: 4 },
              key: 'initialQty',
              required: true,
              rules: [(value) => (value.initialQty > 0 ? '' : t('errors:positive'))],
              props: {
                label: t('materials:attributes.quantities.initial'),
                endAdornment: value.unit ? (
                  <EndAdornmentLabel>{t(`materials:unitSymbol.${value.unit}`)}</EndAdornmentLabel>
                ) : undefined,
              },
            },
            {
              type: ItemType.number,
              grid: { xs: 6, md: 4 },
              key: 'currentQty',
              required: true,
              rules: [(value) => (value.currentQty >= 0 ? '' : t('errors:positive'))],
              props: {
                label: t('materials:attributes.quantities.current'),
                endAdornment: value.unit ? (
                  <EndAdornmentLabel>{t(`materials:unitSymbol.${value.unit}`)}</EndAdornmentLabel>
                ) : undefined,
              },
            },
            {
              type: ItemType.select,
              grid: { xs: 6, sm: 4 },
              key: 'quality',
              required: true,
              props: {
                label: t('materials:attributes.globalQuality'),
                items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
              },
            },
            {
              type: ItemType.select,
              grid: { xs: 6, sm: 4 },
              key: 'type',
              required: true,
              props: {
                items: createOptionsFromEnum(QuantityType, 'materials:quantityType'),
                label: t('materials:attributes.quantities.type'),
              },
            },
            {
              type: ItemType.text,
              grid: { xs: 12, sm: 8 },
              key: 'description',
              props: {
                label: t('materials:attributes.quantities.description'),
              },
            },
          ]}
          value={updatedQuantities}
          setValue={setUpdatedQuantities}
          onClose={setModal.bind(null, false)}
          onSubmit={updateQuantities}
        />
      )}
    </>
  )
}
export default ModalFormMaterial
