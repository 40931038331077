import { useTranslation } from 'react-i18next'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { Mode } from '../../models/commons.models'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'

import PageOrderList from '../../components/page/OrderList.page'
import { sessionQuery } from '../../store/session'
import { useObservable } from '@ngneat/react-rxjs'
const OrderAdminPage = () => {
  useCheckRoute('OrderAdmin', [Mode.admin])
  const { t } = useTranslation()
  const [useImperials] = useObservable(sessionQuery.useImperials)

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.adminOrders')} />
      <PageOrderList useImperials={useImperials} />
    </>
  )
}
export default OrderAdminPage
