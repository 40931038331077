import { DEFAULT_COLORS } from '../constants/colors.constants'
import { Mode } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

// http://admin:3000/
const backofficeConfig = {
  mode: Mode.admin,
  platform: PlatformReference.tm,
  specificCountry: 'FR',
  resources: {
    home: '/tm/home.png',
  },
  meta: {
    icon: '/tm/favicon.ico',
    appleTouchIcon: '/tm/logo192.png',
    manifest: '/tm/manifest.json',
  },
  theme: {
    overrides: {
      MuiToolbar: {
        root: {
          color: '#ffffff',
          backgroundColor: '#000000',
          '& .MuiTablePagination-selectIcon': {
            color: DEFAULT_COLORS.primary,
          },
          '& .MuiTablePagination-actions .MuiButtonBase-root': {
            color: DEFAULT_COLORS.primary,
            '&.Mui-disabled': {
              color: DEFAULT_COLORS.white,
            },
          },
        },
      },
      MuiTypography: {
        h1: { fontSize: '1rem', fontWeight: 600 },
      },
    },
  },
  langs: {
    fr: {
      catalogs: {
        attributes: {
          catalogIcon: 'Projet LIFE Waste 2 Build',
        },
      },
      global: {
        routes: {
          requests: 'Demandes',
          workspace: 'Projets',
        },
        meta: {
          title: 'Life Waste2Build | Vente de matériaux',
          description:
            'La plateforme LIFE Waste2Build est spécialisée dans le réemploi de matériaux décarbonés issus du bâtiment. Elle met en relation revendeurs et acheteurs pour une réutilisation simple et rapide.',
        },
        contactInfo: {
          address: '6 rue René Leduc',
          street: 'BP 35821',
          city: '31505 Toulouse Cedex 5',
        },
      },
    },
  },
}

export default backofficeConfig
