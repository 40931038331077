import { YupUtils } from '../utils/yup.utils'
import { ButtonProps as MuiButtonProps, GridSize } from '@mui/material'
import { KeyboardEventHandler, Ref } from 'react'
import { FileDetails } from './files.models'
import { Location } from './commons.models'
import { Plan } from './catalogs.models'

export interface Grid {
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}
export interface Action {
  icon?: JSX.Element
  label?: string
  color?: 'primary' | 'secondary' | 'success'
  onClick: (event: any) => void
  custom?: JSX.Element
}
export type MenuAction = Omit<Action, 'onClick'> & { items: (Action & { group?: string })[] }
export type Actions = Action | MenuAction
export enum ItemType {
  address = 'address',
  addressRadius = 'addressRadius',
  asyncSelect = 'asyncSelect',
  checkbox = 'checkbox',
  date = 'date',
  interval = 'interval',
  documents = 'documents',
  file = 'file',
  directory = 'directory',
  image = 'image',
  multiSelect = 'multiSelect',
  number = 'number',
  radio = 'radio',
  search = 'search',
  select = 'select',
  text = 'text',
  link = 'link',
  button = 'button',
  info = 'info',
  tooltip = 'tooltip',
  chip = 'chip',
  typo = 'typo',
  group = 'group',
  array = 'array',
  custom = 'custom',
}

interface BaseItem {
  key: string
  hideItem?: boolean
  formatValue?: (value: any) => any
  type?: ItemType
  required?: boolean
  error?: string | boolean | Record<string, any>
  value?: any
  alignItems?: string
  parentValue?: any
  onChange?: any
}

interface BaseAddressItem {
  disabled?: boolean
  label?: string
  types?: string[]
  canChooseCountry?: boolean
  placeholder?: string
  readOnly?: boolean
}
export interface AddressProps extends BaseAddressItem {
  error?: string | boolean
  required?: boolean
  onChange?: (location?: Location) => void
  value?: Location
}
export interface AddressItem extends BaseItem {
  type: ItemType.address
  props: BaseAddressItem
  custom?: unknown
  items?: unknown
}

interface BaseAddressRadiusItem extends Omit<BaseAddressItem, 'types'> {}
export interface AddressRadiusProps extends BaseAddressRadiusItem {
  error?: string | boolean
  required?: boolean
  onChange?: (value?: any) => void
  value?: { location: Location; radius: number }
}
export interface AddressRadiusItem extends BaseItem {
  type: ItemType.addressRadius
  props: BaseAddressRadiusItem
  custom?: unknown
  items?: unknown
}

interface BaseAsyncSelectItem {
  ariaLabel?: 'large-primary'
  disabled?: boolean
  allowNew?: boolean
  getOptions?: (search?: string) => Promise<SelectOption[]>
  options?: SelectOption[]
  label?: string
  minimumSearchLength?: number
  noResultComponent?: JSX.Element
  dataValue?: boolean | string
  dataLabel?: string
  placeholder?: string
  readOnly?: boolean
  searchIfEmpty?: boolean
  tooltip?: string
}
export interface AsyncSelectProps extends BaseAsyncSelectItem {
  error?: string | boolean
  value?: SelectOption | ''
  onChange?: (value?: any) => void
  required?: boolean
}
export interface AsyncSelectItem extends BaseItem {
  type: ItemType.asyncSelect
  props: BaseAsyncSelectItem
  custom?: unknown
  items?: unknown
}

interface BaseCheckboxItem {
  label?: string
  falseValue?: boolean
  children?: JSX.Element
  disabled?: boolean
  readOnly?: boolean
}
export interface CheckboxProps extends BaseCheckboxItem {
  error?: string | boolean
  value?: boolean | undefined
  onChange?: (value: boolean | undefined) => void
  required?: boolean
}
export interface CheckboxItem extends BaseItem {
  type: ItemType.checkbox
  props: BaseCheckboxItem
  custom?: unknown
  items?: unknown
}

interface BaseDateItem {
  blurredFormat?: boolean
  color?: 'primary' | 'secondary'
  disabled?: boolean
  disableFuture?: boolean
  disablePast?: boolean
  format?: string
  size?: 'normal' | 'large'
  label?: string
  placeholder?: string
  readOnly?: boolean
}
export interface DateProps extends BaseDateItem {
  error?: string | boolean
  value?: Date
  onChange?: (date?: any) => void
  required?: boolean
}
export interface DateItem extends BaseItem {
  type: ItemType.date
  props: BaseDateItem
  custom?: unknown
  items?: unknown
}

interface BaseIntervalItem {
  label?: string
  disabled?: boolean
  readOnly?: boolean
  valueLabel?: (value: [number, number]) => JSX.Element
  min: number
  max: number
}
export interface IntervalProps extends BaseIntervalItem {
  error?: string | boolean
  onChange?: (value?: [number, number]) => void
  value?: [number, number]
  required?: boolean
}
export interface IntervalItem extends BaseItem {
  type: ItemType.interval
  props: BaseIntervalItem
  custom?: unknown
  items?: unknown
}

interface BaseDocumentsItem {
  type?: 'image' | 'file' | 'plan'
  multiple?: boolean
  disableClick?: boolean
  hideValues?: boolean
  documents?: FileDetails[] | Plan[]
  documentTitle?: (index: number) => string
  fileTitle?: (index: number) => string
  label: string
  maxFileSize?: number
  cardSize?: number
  readOnly?: boolean
  actionIcon?: JSX.Element
  onSelectFiles?: (files: File[]) => void
  onDocumentClick?: (documentId: string) => void
  onActionDocument?: (documentId: string) => void
  onActionFile?: (index: number) => void
}
export interface DocumentsProps extends BaseDocumentsItem {
  value?: File[]
  error?: string | boolean
  required?: boolean
}
export interface DocumentsItem extends BaseItem {
  type: ItemType.documents
  props: BaseDocumentsItem
  custom?: unknown
  items?: unknown
}

interface BaseFileItem {
  accept?: string
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  placeholder?: string
  maxFileSize?: number
}
export interface FileProps extends BaseFileItem {
  error?: string | boolean
  onChange?: (file?: File) => void
  value?: File | FileDetails
  required?: boolean
}
export interface FileItem extends BaseItem {
  type: ItemType.file
  props: BaseFileItem
  custom?: unknown
  items?: unknown
}

interface BaseDirectoryItem {
  accept?: 'image' | 'all'
  label?: string
  placeholder?: string
  maxFileSize?: number
}
export interface DirectoryProps extends BaseDirectoryItem {
  onChange?: (file?: File[]) => void
}
export interface DirectoryItem extends BaseItem {
  type: ItemType.directory
  props: BaseDirectoryItem
  custom?: unknown
  items?: unknown
}

interface BaseImageItem {
  height?: string
  description?: string
  fileDetails?: FileDetails
  label?: string
  maxFileSize?: number
  readOnly?: boolean
  next?: () => void
  previous?: () => void
}
export interface ImageProps extends BaseImageItem {
  error?: string | boolean
  value?: File
  onChange?: (picture: File) => void
  required?: boolean
}
export interface ImageItem extends BaseItem {
  type: ItemType.image
  props: BaseImageItem
  custom?: unknown
  items?: unknown
}

export interface BaseMultiSelectItem {
  label?: string
  fullWidth?: boolean
  multiSelectedLabel?: (count: number) => string
  disabled?: boolean
  placeholder?: string
  readOnly?: boolean
  size?: 'large' | 'normal'
  items?: SelectOption[]
}
export interface MultiSelectProps extends BaseMultiSelectItem {
  error?: string | boolean
  value?: any[]
  onChange?: (value: any) => void
  required?: boolean
}
export interface MultiSelectItem extends BaseItem {
  type: ItemType.multiSelect
  props: BaseMultiSelectItem
  custom?: unknown
  items?: unknown
}

interface BaseNumberItem {
  disabled?: boolean
  endAdornment?: JSX.Element
  fullWidth?: boolean
  onClick?: () => void
  label?: string
  pattern?: string
  step?: number
  min?: number
  max?: number
  tooltip?: string
  placeholder?: string
  readOnly?: boolean
  showControl?: boolean
}
export interface NumberProps extends BaseNumberItem {
  error?: string | boolean
  onChange?: (value?: number) => void
  value?: number
  required?: boolean
}
export interface NumberItem extends BaseItem {
  type: ItemType.number
  props: BaseNumberItem
  custom?: unknown
  items?: unknown
}

interface BaseRadioItem {
  items?: SelectOption[]
  disabled?: boolean
  label?: string
  readOnly?: boolean
  multiple?: boolean
  split?: boolean
  column?: boolean
}
export interface RadioProps extends BaseRadioItem {
  onChange: (value: any) => void
  error?: string | boolean
  value?: any
  required?: boolean
}
export interface RadioItem extends BaseItem {
  type: ItemType.radio
  props: BaseRadioItem
  custom?: unknown
  items?: unknown
}

interface BaseSearchItem {
  defaultValue?: string
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  fullWidth?: boolean
  placeholder?: string
}
export interface SearchProps extends BaseSearchItem {
  onChange?: (value?: string) => void
  error?: string | boolean
  value?: string
  required?: boolean
}
export interface SearchItem extends BaseItem {
  type: ItemType.search
  props: BaseSearchItem
  custom?: unknown
  items?: unknown
}

interface BaseSelectItem {
  disabled?: boolean
  fullWidth?: boolean
  items?: SelectOption[]
  label?: string
  placeholder?: string
  readOnly?: boolean
  size?: 'large' | 'normal'
}
export interface SelectProps extends BaseSelectItem {
  error?: string | boolean
  onClick?: () => void
  onChange?: (value: any) => void
  value?: any
  required?: boolean
}
export interface SelectItem extends BaseItem {
  type: ItemType.select
  props: BaseSelectItem
  custom?: unknown
  items?: unknown
}

interface BaseTextItem {
  ariaLabel?: string
  disabled?: boolean
  endAdornment?: JSX.Element
  fullWidth?: boolean
  onClick?: () => void
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  inputRef?: Ref<HTMLInputElement>
  label?: string
  minRows?: number
  multiline?: boolean
  pattern?: string
  placeholder?: string
  readOnly?: boolean
  rows?: number
  type?: 'password' | 'email'
  autocomplete?: string
  maxWidth?: string
  tooltip?: string
}
export interface TextProps extends BaseTextItem {
  error?: string | boolean
  onChange?: (value: string) => void
  value?: string
  required?: boolean
}
export interface TextItem extends BaseItem {
  type: ItemType.text
  props: BaseTextItem
  custom?: unknown
  items?: unknown
}

export interface LinkProps extends MuiButtonProps {}
export interface LinkItem extends BaseItem {
  type: ItemType.link
  props: LinkProps
  custom?: unknown
  items?: unknown
}

export interface ButtonProps extends MuiButtonProps {}
export interface ButtonItem extends BaseItem {
  type: ItemType.button
  props: ButtonProps
  custom?: unknown
  items?: unknown
}

export interface TooltipProps {
  title: string | JSX.Element
  children: JSX.Element
}
export interface TooltipItem extends BaseItem {
  type: ItemType.tooltip
  props: TooltipProps
  custom?: unknown
  items?: unknown
}
export interface InfosProps {
  children?: JSX.Element | JSX.Element[]
}
export interface InfosItem extends BaseItem {
  type: ItemType.info
  props: InfosProps
  custom?: unknown
  items?: unknown
}
export interface ChipProps {
  color: string
  label: string
  full?: boolean
  sx?: any
}
export interface ChipItem extends BaseItem {
  type: ItemType.chip
  props: ChipProps
  custom?: unknown
  items?: unknown
}

export interface TypoItem extends BaseItem {
  type: ItemType.typo
  props?: { variant?: string; icon?: any }
  custom?: unknown
  items?: unknown
}

interface BaseArrayItem {
  label?: string
  addLabel?: string
}
export interface ArrayProps extends BaseArrayItem {
  error?: Record<string, any>
  orderable?: boolean
  onChange?: (value: any) => void
  value?: any[]
  required?: boolean
  items: FormItem[]
}
export interface ArrayItem extends BaseItem {
  type: ItemType.array
  props: BaseArrayItem
  custom?: unknown
  items?: FormItem[]
}

export interface GroupProps {
  title?: string
  description?: string
  endTitle?: string
  accordion?: boolean
  error?: boolean | string
}
export interface GroupItem extends BaseItem {
  type: ItemType.group
  items: DetailItem[] | FilterItem[] | FormItem[]
  props?: GroupProps
  custom?: unknown
}
export interface CustomItem extends BaseItem {
  type: ItemType.custom
  items?: unknown
  props?: unknown
  custom: JSX.Element | JSX.Element[]
}

export type Item =
  | AddressItem
  | ArrayItem
  | AddressRadiusItem
  | AsyncSelectItem
  | CheckboxItem
  | DateItem
  | IntervalItem
  | DocumentsItem
  | FileItem
  | DirectoryItem
  | ImageItem
  | MultiSelectItem
  | NumberItem
  | RadioItem
  | SearchItem
  | SelectItem
  | TextItem
  | LinkItem
  | ButtonItem
  | InfosItem
  | TooltipItem
  | ChipItem
  | TypoItem
  | GroupItem
  | CustomItem

export type DetailItem = (
  | (BaseItem & { type?: unknown; props?: unknown; custom?: unknown; items?: unknown })
  | Item
) & { label?: string; column?: boolean; isTitle?: boolean }

export type BaseColumnItem = (
  | (BaseItem & { type?: unknown; props?: unknown; custom?: unknown; items?: unknown })
  | Item
) & { label?: string; minWidth?: string }
export type ColumnItem = BaseColumnItem | ((value: any | undefined) => BaseColumnItem)

export type FilterItem = Item & {
  grid?: Grid
  position?: 'aside' | 'modal'
  isFilled?: boolean
}
// export type FilterItem = BaseFilterItem | ((value: any) => BaseFilterItem)

export type Filter = {
  info?: (filters: Record<string, any>, count: number) => JSX.Element | JSX.Element[] | undefined
  items: FilterItem[]
}
export type BaseFormItem = Item & {
  rules?: (
    | { rule: YupUtils.FieldValidationType; params: any }
    | YupUtils.FieldValidationType
    | ((value: any) => string | undefined)
  )[]
  grid?: Grid
  summary?: string
}
export type FormItem = BaseFormItem | ((value: any) => BaseFormItem)

export interface SelectOption {
  label: string
  value: any
  data?: any
}
