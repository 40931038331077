import { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useObservable } from '@ngneat/react-rxjs'
import Constants from '../../constants'

import dayjs from '../../utils/dayjs.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

import { sessionQuery, sessionService } from '../../store/session'
import { organizationsService } from '../../store/organizations'
import { cartsService } from '../../store/carts'

import { materialsService } from '../../store/materials'
import { catalogsService } from '../../store/catalogs'
import { ordersService } from '../../store/orders'
import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import { Mode } from '../../models/commons.models'
import { FilterItem, Filter, ItemType, Actions } from '../../models/props.models'
import {
  OrdersPagination,
  Order,
  OrderStatus,
  convertOrder2Imperials,
  convertOrder2Meters,
  Product,
} from '../../models/orders.models'
import { UserType } from '../../models/users.models'
import OrderList, { OrderListProps } from '../order/List.order'
import OrderModalDetails from '../order/ModalDetails.order'
import OrderModalDeny from '../order/ModalDeny.order'
import OrderModalFinish from '../order/ModalFinish.order'
import OrderModalClientAccept from '../order/ModalClientAccept.order'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { ListRef } from '../common/List.common'
import { TermsOfSale } from '../../models/materials.models'

interface PageOrderListProps
  extends Omit<
    OrderListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
    | 'isCatalogPage'
  > {
  catalog?: string
  seller?: string
  clientOrganization?: string
  useImperials: boolean
}

const PageOrderList: React.FC<PageOrderListProps> = (props) => {
  const {
    seller,
    clientOrganization,
    useImperials,
    isCatalogOwner,
    isClient,
    catalog,
    ...listProps
  } = props

  const listRef = useRef<ListRef | null>(null)
  const show = useSnackbar()
  const { t } = useTranslation()
  const [modal, setModal] = useModal<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [openedOrder, setOpenedOrder] = useState<Order>()
  const [user] = useObservable(sessionQuery.user)
  const adminPlatform =
    Constants.mode === Mode.admin ? sessionService.getAdminPlatform() : undefined

  useEffect(() => {
    const getModal = async () => {
      if (modal) {
        setLoading(true)
        try {
          const order = await ordersService.getOrderById(
            modal.replace('deny.', '').replace('clientAccept.', '').replace('finish.', ''),
          )
          setOpenedOrder(useImperials ? convertOrder2Imperials(order) : order)
        } catch (err: any) {
          show(err)
        }
        setLoading(false)
      } else {
        setOpenedOrder(undefined)
      }
    }
    getModal()
  }, [show, modal, useImperials])

  const parseFilter = useCallback(
    (filters: any) => {
      const catalogOwner = filters.catalogOwner ? filters.catalogOwner.value : undefined
      let periodStart: any = undefined
      let periodEnd: any = undefined
      let date: any = undefined
      if (filters.periodStart) {
        date = dayjs(filters.periodStart)
        if (date.isValid()) {
          periodStart = date.startOf('month').toDate()
          periodEnd = date.endOf('month').toDate()
        }
      }
      return {
        ...filters,
        periodStart,
        periodEnd,
        owned: Constants.mode !== Mode.admin,
        catalog: catalog ? catalog : filters.catalog ? filters.catalog?.value : undefined,
        catalogOwner: seller ?? (isCatalogOwner ? user?.organization._id : catalogOwner),
        clientOrganization: clientOrganization,
        client: isClient && !clientOrganization ? user?._id : undefined,
      }
    },
    [seller, clientOrganization, catalog, user, isClient, isCatalogOwner],
  )

  const getOrders = useCallback(
    async (filters: any): Promise<OrdersPagination> => {
      return await ordersService.getOrders(parseFilter(filters))
    },
    [parseFilter],
  )

  const onExport = useCallback(
    async (filters: any) => {
      setLoading(true)

      try {
        await ordersService.getOrdersCsv(parseFilter(filters))
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    },
    [show, parseFilter],
  )
  const onDownload = useCallback(
    async (order: Order) => {
      setLoading(true)
      try {
        await ordersService.getOrderPdf(order)
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    },
    [show],
  )
  const onUpdate = useCallback(
    async (order: Order, update: { status: OrderStatus }) => {
      setLoading(true)
      try {
        await ordersService.updateOrder(order._id, update)
        if (update.status === OrderStatus.clientPending) {
          show(t('orders:actions.clientPending.success'), 'success')
        } else if (update.status === OrderStatus.accepted) {
          show(t('orders:actions.accept.success'), 'success')
        } else if (update.status === OrderStatus.canceled) {
          show(t('orders:actions.cancel.success'), 'success')
        }
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    },
    [show, t],
  )
  const addToCart = useCallback(
    async (order: Order) => {
      setLoading(true)
      try {
        for (let i = 0; i < order.products.length; i++) {
          try {
            //check if public
            await materialsService.getMaterial(order.products[i].material._id, true)
            cartsService.addToCart(order.products[i].material._id, order.products[i].quantity)
          } catch (err) {}
        }
        show(t('orders:actions.addToCart.success'), 'success')
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    },
    [show, t],
  )

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.search,
          key: 'search',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('orders:components.list.searchFilter'),
          },
        },
        {
          type: ItemType.multiSelect,
          key: 'status',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('orders:components.list.statusFilter'),
            multiSelectedLabel: (count: number) => t('global:inputs.selectedOptions', { count }),
            items: createOptionsFromEnum(OrderStatus, 'orders:status'),
          },
        },
        {
          type: ItemType.date,
          key: 'periodStart',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            format: 'MM/YYYY',
            blurredFormat: true,
          },
        },
        ...((Constants.mode === Mode.admin && !catalog
          ? [
              {
                type: ItemType.asyncSelect,
                key: 'catalogOwner',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  placeholder: t('orders:components.list.catalogOwnerFilter'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) =>
                    organizationsService.getOrganizationOptions(search),
                },
              },
            ]
          : []) as FilterItem[]),
        ...(((Constants.mode === Mode.admin || isCatalogOwner) && !catalog
          ? [
              {
                type: ItemType.asyncSelect,
                key: 'catalog',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  placeholder: t('orders:components.list.catalog'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) => catalogsService.getCatalogOptions({ search }),
                },
              },
            ]
          : []) as FilterItem[]),
        ...((!isClient
          ? [
              {
                type: ItemType.button,
                key: 'exportCsv',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  fullWidth: true,
                  children: t('orders:components.list.exportCsv'),
                  onClick: async () => onExport(listRef.current?.filter ?? {}),
                },
              },
            ]
          : []) as FilterItem[]),
      ],
    }),
    [t, catalog, isClient, onExport, isCatalogOwner],
  )

  const checkAction = useCallback(
    (type: 'admin' | 'user', order: Order) => {
      if (type === 'admin') {
        return (
          user?.organization._id === order.catalog.organization._id ||
          user?.type === UserType.admin ||
          (user?.type === UserType.platformAdmin &&
            user?.platformOwned === order.catalog.organization.platform)
        )
      }
      return (
        user?._id === order.client._id ||
        user?.type === UserType.admin ||
        (user?.type === UserType.platformAdmin &&
          user?.platformOwned === order.client.organization.platform)
      )
    },
    [user],
  )

  const orderActions = useCallback(
    (order: Order): Actions | undefined => {
      const actions = [
        ...(isClient
          ? [
              {
                label: t('orders:actions.addToCart.label'),
                onClick: () => {
                  addToCart(order)
                },
              },
            ]
          : []),
        ...(order.status !== OrderStatus.denied &&
        order.status !== OrderStatus.canceled &&
        order.status !== OrderStatus.finished
          ? [
              {
                label: t('orders:actions.downloadPdf.label'),
                onClick: () => {
                  onDownload(order)
                },
              },
            ]
          : []),
        ...(order.status === OrderStatus.accepted && checkAction('admin', order)
          ? [
              {
                label: t('orders:actions.finish.label'),
                onClick: async () => {
                  setModal(`finish.${order._id}`)
                },
              },
            ]
          : []),
        ...(((order.status === OrderStatus.pending && !order.priceModified) ||
          order.status === OrderStatus.clientAccepted) &&
        checkAction('admin', order)
          ? [
              {
                label: t('orders:actions.accept.label'),
                onClick: async () => {
                  await onUpdate(order, { status: OrderStatus.accepted })
                  listRef?.current?.updateValues()
                },
              },
            ]
          : []),
        ...(order.status === OrderStatus.pending &&
        order.priceModified &&
        checkAction('admin', order)
          ? [
              {
                label: t('orders:actions.clientPending.label'),
                onClick: async () => {
                  await onUpdate(order, { status: OrderStatus.clientPending })
                  listRef?.current?.updateValues()
                },
              },
            ]
          : []),
        ...((order.status === OrderStatus.clientAccepted ||
          order.status === OrderStatus.pending ||
          order.status === OrderStatus.pricePending) &&
        checkAction('admin', order)
          ? [
              {
                label: t('orders:actions.deny.label'),
                onClick: async () => {
                  setModal(`deny.${order._id}`)
                },
              },
            ]
          : []),
        ...(order.status === OrderStatus.clientPending && checkAction('user', order)
          ? [
              {
                label: t('orders:actions.clientAccept.label'),
                onClick: async () => {
                  setModal(`clientAccept.${order._id}`)
                },
              },
            ]
          : []),
        ...((order.status === OrderStatus.clientPending ||
          order.status === OrderStatus.pending ||
          order.status === OrderStatus.pricePending) &&
        checkAction('user', order)
          ? [
              {
                label: t('orders:actions.cancel.label'),
                onClick: async () => {
                  await onUpdate(order, { status: OrderStatus.canceled })
                  listRef?.current?.updateValues()
                },
              },
            ]
          : []),
      ]

      return actions.length > 0 ? { items: actions } : undefined
    },
    [t, onUpdate, onDownload, checkAction, setModal, addToCart, isClient],
  )

  return (
    <>
      {loading && <LoaderOverlay />}
      <OrderList
        {...listProps}
        adminPlatform={adminPlatform}
        disableTableScroll={!!catalog}
        isCatalogPage={!!catalog}
        disabledPagination={Constants.mode !== Mode.admin}
        ref={listRef}
        isClient={isClient}
        isCatalogOwner={isCatalogOwner}
        getValues={getOrders}
        onValueClick={(order: Order) => setModal(order._id)}
        filter={filter}
        valueActions={orderActions}
      />
      {modal && openedOrder && !modal.includes('deny') && !modal.includes('finish') && (
        <OrderModalDetails
          adminPlatform={adminPlatform}
          order={openedOrder}
          isClient={isClient}
          onClose={() => setModal('')}
        />
      )}
      {modal && openedOrder && modal.includes('deny') && (
        <OrderModalDeny
          onSubmit={async (data: any) => {
            await ordersService.updateOrder(openedOrder._id, {
              status: OrderStatus.denied,
              deniedFor: data.deniedFor,
            })
          }}
          onSuccess={async () => {
            show(t('orders:actions.deny.success'), 'success')
            listRef?.current?.updateValues()
          }}
          onClose={() => setModal('')}
        />
      )}
      {modal && openedOrder && modal.includes('finish') && (
        <OrderModalFinish
          order={openedOrder}
          useImperials={useImperials}
          onSubmit={async (data: any) => {
            let orderData = {
              status: OrderStatus.finished,
              removalOrder: data.removalOrder,
              finishedComment: data.finishedComment,
              discount: data.discount,
              products: data.products,
            }
            orderData = useImperials ? convertOrder2Meters(orderData as Order) : orderData

            await ordersService.updateOrder(openedOrder._id, {
              ...orderData,
              products: orderData.products.map((product: Product) => {
                return {
                  material: product.material._id,
                  termsOfSale: product.termsOfSale,
                  price: product.termsOfSale === TermsOfSale.sale ? product.price : undefined,
                  discount: product.termsOfSale === TermsOfSale.sale ? product.discount : undefined,
                  quantity: product.quantity,
                }
              }),
            })
          }}
          onSuccess={async () => {
            show(t('orders:actions.finish.success'), 'success')
            listRef?.current?.updateValues()
          }}
          onClose={() => setModal('')}
        />
      )}
      {modal && openedOrder && modal.includes('clientAccept') && (
        <OrderModalClientAccept
          order={openedOrder}
          onSubmit={async (data: any) => {
            let orderData = {
              status: OrderStatus.clientAccepted,
              products: data.products,
            }
            orderData = useImperials ? convertOrder2Meters(orderData as Order) : orderData
            await ordersService.updateOrder(openedOrder._id, {
              ...orderData,
              products: orderData.products.map((product: Product) => {
                return {
                  material: product.material._id,
                  quantity: product.quantity,
                }
              }),
            })
          }}
          onSuccess={async () => {
            show(t('orders:actions.clientAccept.success'), 'success')
            listRef?.current?.updateValues()
          }}
          onClose={() => setModal('')}
        />
      )}
    </>
  )
}
export default PageOrderList
