import Stack from '../components/common/Stack.common'
import Constants from '../constants'
import { alpha, Grid, styled, Typography, Box, Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useTranslation } from 'react-i18next'

const HORIZONTAL_PADDING = 70
const SUB_HEADER_OFFSET = 60

const MainContainer = styled(Stack)({ width: '100%' })

const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: `60px ${HORIZONTAL_PADDING}px 0px`,
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
}))
const Header = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '340px',
  minHeight: '340px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `
  linear-gradient(to left, ${alpha(theme.palette.gradient2, 0.9)}, ${alpha(
    theme.palette.gradient1,
    0.9,
  )}),
  linear-gradient(to left, ${alpha(Constants.colors.black, 0.2)}, ${alpha(
    Constants.colors.black,
    0.2,
  )}),
  url(/tm/home.png)`,
  paddingBottom: `${SUB_HEADER_OFFSET}px`,
}))

const HeaderTitle = styled(Typography)({
  fontSize: '1.875rem',
  color: Constants.colors.white,
  fontWeight: 800,
})

const SubHeader = styled(Stack)(({ theme }) => ({
  maxWidth: '1090px',
  paddingTop: '60px',
  backgroundColor: theme.palette.white,
  marginTop: `-${SUB_HEADER_OFFSET}px`,
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const SubHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.875rem',
  color: theme.palette.home.title,
  fontWeight: 800,
  marginBottom: '17px',
  textAlign: 'unset',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}))

const SubHeaderSubTitleContainer = styled(Typography)({
  textAlign: 'center',
  marginBottom: '30px',
  whiteSpace: 'pre-line',
})

const SubHeaderSubTitle = styled('span')({
  fontSize: '.9375rem',
  fontWeight: 500,
})

const SubHeaderSubTitleStrong = styled('span')({
  fontSize: '.9375rem',
  fontWeight: 700,
})

const OurSolutionSection = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  marginTop: '50px',
  position: 'relative',
  zIndex: 10,
  '&::before': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    backgroundColor: alpha(theme.palette.card, 0.15),
    clipPath: 'polygon(0 180px, 100% 0, 100% 100%, 0% 100%)',
  },
  padding: `60px ${HORIZONTAL_PADDING}px 90px`,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.875rem',
  color: theme.palette.home.title,
  fontWeight: 800,
  lineHeight: 1.25,
  alignSelf: 'flex-start',
}))

const OurSolutionTextContainer = styled(Typography)({
  textAlign: 'center',
  marginBottom: '20px',
  maxWidth: '1090px',
  margin: 'auto',
  whiteSpace: 'pre-line',
})

const OurSolutionText = styled('span')({ fontSize: '.9375rem', fontWeight: 500 })

const OurSolutionTextStrong = styled('span')({ fontSize: '.9375rem', fontWeight: 700 })

const OurSolutionCards = styled(Grid)(({ theme }) => ({
  marginTop: '40px',
  maxWidth: '1000px',
  '& .MuiGrid-item:nth-child(1) .MuiBox-root': {
    backgroundColor: theme.palette.card1,
  },
  '& .MuiGrid-item:nth-child(2) .MuiBox-root': {
    backgroundColor: theme.palette.card2,
  },
}))

const OurSolutionCard = styled(Stack)(({ theme }) => ({
  height: '370px',
  padding: '0 53px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 38px',
  },
}))

const OurSolutionCardText = styled(Typography)({
  fontSize: '.9375rem',
  fontWeight: 500,
  color: Constants.colors.white,
  textAlign: 'center',
})

const OurSolutionCardTextBold = styled(OurSolutionCardText)({ fontWeight: 700 })

const DiscoverButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiTypography-root': {
      color: `${Constants.colors.primary} !important`,
    },
  },
  '& .MuiBox-root[aria-label=dialog-btn]': {
    backgroundColor: Constants.colors.black,
    clipPath: 'polygon(0 0, 100% 0, 100% 75%, 30.5px 74%, 0 100%, 0% 75%)',
    height: '100px',
    padding: '0 64px',
    '& > .MuiTypography-root': {
      fontWeight: 800,
      color: Constants.colors.white,
      paddingTop: '22px',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
    },
  },
  marginTop: '90px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '44px',
    '& .MuiBox-root[aria-label=dialog-btn]': {
      '& > .MuiTypography-root': {
        fontSize: '0.75rem',
      },
    },
  },
}))

const ObjectivesSection = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '1600px',
  padding: `10px ${HORIZONTAL_PADDING}px 0`,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

const ObjectivesIcon = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.home.constructionCycle.step2, 0.15),
  '& img': {
    width: '63px',
  },
  width: '144px',
  height: '144px',
  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '21px',
    },
  },
}))

const ObjectivesCardTextContainer = styled(Typography)(({ theme }) => ({
  maxWidth: '220px',
  lineHeight: 1.467,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}))

const ObjectivesCardTextStrong = styled('span')({ fontWeight: 700 })

const EconomySection = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: `90px ${HORIZONTAL_PADDING}px`,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

const EconomyTextContainer = styled(Typography)({
  textAlign: 'center',
  marginBottom: '60px',
  marginTop: '35px',
  maxWidth: '1090px',
  margin: 'auto',
  whiteSpace: 'pre-line',
})

const EconomyText = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '.9375rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '.75rem',
  },
}))

const EconomyTextUrl = styled(EconomyText)({
  cursor: 'pointer',
  color: Constants.colors.primary,
  textDecoration: 'underline',
})

const EconomyTextStrong = styled('span')({ fontSize: '.9375rem', fontWeight: 700 })

const EconomyCards = styled(Grid)({
  maxWidth: '1300px',
})

const EconomyCard = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.card, 0.15),
  height: '100%',
  textAlign: 'center',
  minHeight: '0px',
  padding: '30px 70px',
  [theme.breakpoints.down('sm')]: {
    minHeight: '230px',
    padding: '20px',
  },
}))

const Percentage = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 800,
  color: theme.palette.home.constructionCycle.step2,
}))

const TMSection = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.home.title, 0.05),
  height: '290px',
  width: '100%',
}))

const TMSectionTitle = styled(Typography)({
  fontSize: '1.375rem',
  fontWeight: 800,
  textAlign: 'center',
})

const TMLogo = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  marginTop: '20px',
  width: '180px',
  '& img': {
    width: '100%',
  },
})

const PartnersSection = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '290px',
  padding: `20px ${HORIZONTAL_PADDING}px`,
})

const PartnersSectionTitle = styled(Typography)({
  fontSize: '1.375rem',
  fontWeight: 800,
  textAlign: 'center',
})

const PartnersLogosContainer = styled(Stack)({
  flexWrap: 'wrap',
  width: '100%',
  marginTop: '20px',
})

const PartnerLogo = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '& img': {
    width: '100%',
  },
  height: '120px',
  width: '180px',
  [theme.breakpoints.down('sm')]: {
    height: '80px',
    width: '100px',
  },
}))

const AdminsSection = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.home.title, 0.05),
  width: '100%',
  height: '290px',
  padding: `0px ${HORIZONTAL_PADDING}px`,
}))

const AdminsSectionTitle = styled(Typography)({
  fontSize: '1.375rem',
  fontWeight: 800,
  textAlign: 'center',
})

const AdminsLogosContainer = styled(Stack)({
  flexWrap: 'wrap',
  width: '100%',
  marginTop: '20px',
})

const AdminLogo = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '& img': {
    width: '100%',
  },
  height: '120px',
  width: '180px',
  [theme.breakpoints.down('sm')]: {
    height: '80px',
    width: '100px',
  },
}))

const SignatoriesSection = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '0',
  padding: `60px ${HORIZONTAL_PADDING}px`,
  '& img': {
    width: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
    padding: '0',
    '& img': {
      width: '100%',
    },
  },
}))

const SignatoriesTitle = styled(Typography)({
  fontSize: '1.375rem',
  fontWeight: 800,
  textAlign: 'center',
  marginBottom: '15px',
})

const WhoAreWe = () => {
  const { t: tI18n } = useTranslation()
  // No check since the trad only exist on tmConfig
  const t = (string: any) => {
    return tI18n(string as any)
  }

  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const openUrlInNewTab = (url?: string) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <MainContainer alignItems="center">
      <HeaderContainer>
        <Header alignItems="center" justifyContent="center">
          <HeaderTitle>{t('whoAreWe:header.title')}</HeaderTitle>
        </Header>
      </HeaderContainer>
      <SubHeader alignItems="center" justifyContent="center">
        <SubHeaderTitle>{t('whoAreWe:subHeader.title')}</SubHeaderTitle>
        <SubHeaderSubTitleContainer>
          <SubHeaderSubTitleStrong>
            {t('whoAreWe:subHeader.subtitle1.part1')}
          </SubHeaderSubTitleStrong>
          <br />
          <SubHeaderSubTitle>{t('whoAreWe:subHeader.subtitle1.part2')}</SubHeaderSubTitle>
        </SubHeaderSubTitleContainer>
      </SubHeader>
      <ObjectivesSection alignItems="center">
        <Grid spacing={4} container justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Stack
              spacing={downSm ? '12px' : '33px'}
              justifyContent="center"
              alignItems="center"
              direction={downSm ? 'row' : 'column'}>
              <ObjectivesIcon justifyContent="center" alignItems="center">
                <img src="/tm/whoAreWe/graphic_arrow_down.svg" alt="" />
              </ObjectivesIcon>
              <ObjectivesCardTextContainer>
                <ObjectivesCardTextStrong>
                  {t('whoAreWe:objectives.objective1')}
                </ObjectivesCardTextStrong>
              </ObjectivesCardTextContainer>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Stack
              spacing={downSm ? '12px' : '33px'}
              justifyContent="center"
              alignItems="center"
              direction={downSm ? 'row' : 'column'}>
              <ObjectivesIcon justifyContent="center" alignItems="center">
                <img src="/tm/whoAreWe/group.svg" alt="" />
              </ObjectivesIcon>
              <ObjectivesCardTextContainer>
                <ObjectivesCardTextStrong>
                  {t('whoAreWe:objectives.objective2')}
                </ObjectivesCardTextStrong>
              </ObjectivesCardTextContainer>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Stack
              spacing={downSm ? '12px' : '33px'}
              justifyContent="center"
              alignItems="center"
              direction={downSm ? 'row' : 'column'}>
              <ObjectivesIcon justifyContent="center" alignItems="center">
                <img src="/tm/whoAreWe/recycle.svg" alt="" />
              </ObjectivesIcon>
              <ObjectivesCardTextContainer>
                <ObjectivesCardTextStrong>
                  {t('whoAreWe:objectives.objective3')}
                </ObjectivesCardTextStrong>
              </ObjectivesCardTextContainer>
            </Stack>
          </Grid>
        </Grid>
      </ObjectivesSection>
      <OurSolutionSection>
        <SectionTitle>{t('whoAreWe:ourSolution.title')}</SectionTitle>
        <Stack mt={downSm ? '79px' : '120px'}>
          <OurSolutionTextContainer>
            <OurSolutionText>{t('whoAreWe:ourSolution.description.part1')}</OurSolutionText>
            <OurSolutionTextStrong>
              {t('whoAreWe:ourSolution.description.part2')}
            </OurSolutionTextStrong>
          </OurSolutionTextContainer>
          <OurSolutionTextContainer>
            <OurSolutionText>{t('whoAreWe:ourSolution.description.part3')}</OurSolutionText>
            <OurSolutionTextStrong>
              {t('whoAreWe:ourSolution.description.part4')}
            </OurSolutionTextStrong>
            <OurSolutionText>{t('whoAreWe:ourSolution.description.part5')}</OurSolutionText>
          </OurSolutionTextContainer>
        </Stack>
        <OurSolutionCards spacing={6} container>
          <Grid item xs={12} sm={6}>
            <OurSolutionCard spacing="33px" justifyContent="center" alignItems="center">
              <img src="/tm/whoAreWe/propose.svg" alt="" style={{ width: '84px' }} />
              <OurSolutionCardTextBold>
                {t('whoAreWe:ourSolution.solution1.title')}
              </OurSolutionCardTextBold>
              <OurSolutionCardText>
                {t('whoAreWe:ourSolution.solution1.description')}
              </OurSolutionCardText>
            </OurSolutionCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <OurSolutionCard spacing="33px" justifyContent="center" alignItems="center">
              <img src="/tm/whoAreWe/cursor.svg" alt="" style={{ width: '53px' }} />
              <OurSolutionCardTextBold>
                {t('whoAreWe:ourSolution.solution2.title')}
              </OurSolutionCardTextBold>
              <OurSolutionCardText>
                {t('whoAreWe:ourSolution.solution2.description')}
              </OurSolutionCardText>
            </OurSolutionCard>
          </Grid>
        </OurSolutionCards>
      </OurSolutionSection>
      <EconomySection>
        <SectionTitle>{t('whoAreWe:economy.title')}</SectionTitle>
        <EconomyTextContainer>
          <EconomyText>{t('whoAreWe:economy.part1')}</EconomyText>
          <EconomyTextUrl
            onClick={() =>
              openUrlInNewTab(
                'https://metropole.toulouse.fr/institutions-et-territoires/emploi-et-vie-economique/projets-europeens/life-waste2build',
              )
            }>
            {t('whoAreWe:economy.lw2b')}
          </EconomyTextUrl>
          <EconomyText>{t('whoAreWe:economy.part1&5')}</EconomyText>
          <EconomyTextStrong>{t('whoAreWe:economy.part2')}</EconomyTextStrong>
          <br></br>
          <br></br>
          <EconomyText>{t('whoAreWe:economy.part3')}</EconomyText>
          <EconomyTextStrong>{t('whoAreWe:economy.part4')}</EconomyTextStrong>
          <br></br>
          <br></br>
          <EconomyText>{t('whoAreWe:economy.part5')}</EconomyText>
        </EconomyTextContainer>
        <Stack alignItems="center">
          <EconomyCards container spacing={downSm ? 2 : 5}>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <Percentage>58</Percentage>
                <EconomyText>{t('whoAreWe:economy.card1')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <Percentage>85%</Percentage>
                <EconomyText>{t('whoAreWe:economy.card2')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <Percentage>5%</Percentage>
                <EconomyText>{t('whoAreWe:economy.card3')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <Percentage>1</Percentage>
                <EconomyText>{t('whoAreWe:economy.card4')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <img alt="" src="/tm/whoAreWe/check.svg" />
                <EconomyText>{t('whoAreWe:economy.card5')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <img alt="" src="/tm/whoAreWe/file.svg" />
                <EconomyText>{t('whoAreWe:economy.card6')}</EconomyText>
              </EconomyCard>
            </Grid>
            <Grid item xs={downSm ? 6 : 3}>
              <EconomyCard alignItems="center" spacing="15px">
                <img alt="" src="/tm/whoAreWe/bag.svg" />
                <EconomyText>{t('whoAreWe:economy.card7')}</EconomyText>
              </EconomyCard>
            </Grid>
          </EconomyCards>
        </Stack>
        <DiscoverButton
          disableRipple
          onClick={() =>
            openUrlInNewTab(
              'https://metropole.toulouse.fr/institutions-et-territoires/emploi-et-vie-economique/projets-europeens/life-waste2build#la-boite-outils',
            )
          }>
          <Box aria-label="dialog-btn">
            <Typography>{t('whoAreWe:tools.discoverButton')}</Typography>
          </Box>
        </DiscoverButton>
      </EconomySection>
      <TMSection>
        <TMSectionTitle>{t('whoAreWe:coordinated.title')}</TMSectionTitle>
        <TMLogo onClick={() => openUrlInNewTab('https://metropole.toulouse.fr')}>
          <img src="/tm/whoAreWe/toulouse_metropole.png" alt="" />
        </TMLogo>
      </TMSection>
      <PartnersSection alignItems="center">
        <PartnersSectionTitle>{t('whoAreWe:partners.title')}</PartnersSectionTitle>
        <PartnersLogosContainer
          spacing={downSm ? 0 : '35px'}
          direction={downSm ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center">
          <PartnerLogo onClick={() => openUrlInNewTab('http://www.cstb.fr/fr/')}>
            <img src="/tm/whoAreWe/cstb.png" alt="" />
          </PartnerLogo>
          <PartnerLogo onClick={() => openUrlInNewTab('https://www.envirobat-oc.fr')}>
            <img src="/tm/whoAreWe/envirobat.png" alt="" />
          </PartnerLogo>
          <PartnerLogo
            onClick={() =>
              openUrlInNewTab(
                'https://www.ffbatiment.fr/organisation-ffb/federations-departementales-chambres-syndicales/haute-garonne',
              )
            }>
            <img src="/tm/whoAreWe/ffb.png" alt="" />
          </PartnerLogo>
          <PartnerLogo onClick={() => openUrlInNewTab('https://www.synethic.fr')}>
            <img src="/tm/whoAreWe/synethic.png" alt="" />
          </PartnerLogo>

          <PartnerLogo onClick={() => openUrlInNewTab('https://institut-economie-circulaire.fr')}>
            <img src="/tm/whoAreWe/institut_national_economie_circulaire.png" alt="" />
          </PartnerLogo>
          <PartnerLogo onClick={() => openUrlInNewTab('https://www.tbs-education.fr')}>
            <img src="/tm/whoAreWe/tbs_education.png" alt="" />
          </PartnerLogo>
          <PartnerLogo onClick={() => openUrlInNewTab('https://metropole.toulouse.fr/')}>
            <img src="/tm/whoAreWe/toulouse_metropole.png" alt="" />
          </PartnerLogo>
        </PartnersLogosContainer>
      </PartnersSection>
      <AdminsSection alignItems="center">
        <AdminsSectionTitle>{t('whoAreWe:admins.title')}</AdminsSectionTitle>
        <AdminsLogosContainer
          spacing="35px"
          direction="row"
          alignItems="center"
          justifyContent="center">
          <AdminLogo onClick={() => openUrlInNewTab('https://metropole.toulouse.fr')}>
            <img src="/tm/whoAreWe/toulouse_metropole.png" alt="" />
          </AdminLogo>
          <AdminLogo onClick={() => openUrlInNewTab('https://www.synethic.fr')}>
            <img src="/tm/whoAreWe/synethic.png" alt="" />
          </AdminLogo>
        </AdminsLogosContainer>
      </AdminsSection>
      <SignatoriesSection alignItems="center">
        <SignatoriesTitle>{t('whoAreWe:signatories.title')}</SignatoriesTitle>
        <img src="/tm/whoAreWe/signataires.jpg" alt="" />
      </SignatoriesSection>
    </MainContainer>
  )
}

export default WhoAreWe
