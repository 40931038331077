import React, { useEffect, useRef, useState } from 'react'
import { Box, InputLabel, FormHelperText } from '@mui/material'
import { DirectoryProps } from '../../../models/props.models'
// @ts-ignore
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise/index.js'
import constants from '../../../constants'
import { useTranslation } from 'react-i18next'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { AddAPhoto as AddAPhotoIcon } from '@mui/icons-material'

const DirectoryInput: React.FC<DirectoryProps> = (props): JSX.Element => {
  const dropzone = useRef<HTMLDivElement | undefined>(undefined)
  const { maxFileSize, placeholder, accept, label, onChange } = props
  const { t } = useTranslation()
  const show = useSnackbar()
  const [dragOver, setDragOver] = useState<boolean>(false)

  useEffect(() => {
    let dragover = (evt: any) => evt.preventDefault()
    let drop = async (evt: any) => {
      evt.preventDefault()
      const files = await getFilesFromDataTransferItems(evt.dataTransfer.items)

      if (maxFileSize && files.some((file: File) => file.size / (1024 * 1024) > maxFileSize)) {
        show(t('errors:fileSize', { size: maxFileSize }), 'warning')
      }

      onChange?.(
        accept === 'all'
          ? files
          : files.filter(
              (file: File) =>
                file.type.includes('image/') &&
                (!maxFileSize || file.size / (1024 * 1024) <= maxFileSize),
            ),
      )
    }

    let element = dropzone.current
    element?.addEventListener('dragover', dragover)
    element?.addEventListener('drop', drop)
    return () => {
      element?.removeEventListener('dragover', dragover)
      element?.removeEventListener('drop', drop)
    }
  }, [accept, onChange, show, t, maxFileSize])
  return (
    <Box
      onDragOver={() => setDragOver(true)}
      onDragLeave={() => setDragOver(false)}
      onDrop={() => setDragOver(false)}
      ref={dropzone}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        background: constants.colors.textFieldBackground,
        borderRadius: 4,
        padding: '5px 10px',
        minHeight: '70px',
        border: `1px solid ${
          dragOver ? constants.colors.primary : constants.colors.textFieldBackground
        }`,
      }}>
      {/* color={} */}
      <Box display="flex" alignItems="end">
        <AddAPhotoIcon
          sx={{
            marginRight: '5px',
            color: dragOver ? constants.colors.primary : constants.colors.secondaryText,
          }}
        />
        {!!label && <InputLabel sx={{ margin: 0 }}>{label}</InputLabel>}
      </Box>
      {!!placeholder && <FormHelperText>{placeholder}</FormHelperText>}
      {!!maxFileSize && (
        <FormHelperText>({t('global:inputs.maxSize', { size: maxFileSize })})</FormHelperText>
      )}
    </Box>
  )
}

export default DirectoryInput
