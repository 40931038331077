import { Mode } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

// http://tm:3000/

const tmConfig = {
  additionals: [{ path: 'lw2b', key: 'whoAreWe:header.title' }],
  specificCountry: 'FR',
  mode: Mode.front,
  platform: PlatformReference.tm,
  resources: {
    home: '/tm/home.png',
  },
  meta: {
    icon: '/tm/favicon.ico',
    appleTouchIcon: '/tm/logo192.png',
    manifest: '/tm/manifest.json',
  },
  ui: {
    transparentHeader: false,
    mapCenter: [1.433333, 43.6],
    mapZoom: 11,
  },
  langs: {
    fr: {
      catalogs: {
        attributes: {
          catalogIcon: 'Projet LIFE Waste 2 Build',
        },
      },
      global: {
        pages: {
          home: {
            title: 'PLATEFORME POUR LE RÉEMPLOI DE MATÉRIAUX DE CONSTRUCTION',
            subtitle: "pour des chantiers sobres sur Toulouse Métropole et l'Occitanie",
          },
        },
        meta: {
          title: 'Life Waste2Build | Vente de matériaux',
          description:
            'La plateforme LIFE Waste2Build est spécialisée dans le réemploi de matériaux décarbonés issus du bâtiment. Elle met en relation revendeurs et acheteurs pour une réutilisation simple et rapide.',
        },
        contactInfo: {
          address: '6 rue René Leduc',
          street: 'BP 35821',
          city: '31505 Toulouse Cedex 5',
        },
      },
      whoAreWe: {
        header: {
          title: 'Qui sommes nous ?',
        },
        subHeader: {
          title: 'Plateforme LIFE Waste2Build',
          subtitle1: {
            part1:
              'Cette plateforme numérique met en relation les professionnels pour des projets de réemploi sur Toulouse et sa région.',
            part2: "En l'utilisant, vous contribuez concrètement à :",
          },
          subtitle2: {
            part1: 'Life Waste2Build accompagnera ',
            part2: '58 chantiers expérimentaux du territoire',
            part3:
              " de rénovation, de déconstruction, d'aménagement ou de construction, de maîtrise d'ouvrage publique ou privée en visant deux objectifs opérationnels :",
          },
        },
        tools: {
          discoverButton: 'Découvrir notre boîte à outils',
        },
        objectives: {
          title: 'Les objectifs',
          objective1: 'Réduire l’impact environnemental de vos projets',
          objective2: "Favoriser la création d'emploi local",
          objective3: 'Structurer les nouvelles filières économiques du réemploi',
        },
        ourSolution: {
          title: 'Notre solution',
          description: {
            part1:
              'Notre solution vous permet de trouver dans nos catalogues des matières premières pour vos projets comportant des objectifs de fourniture en produits de réemploi. ',
            part2:
              "C’est une alternative économique et écologique qui réduit les déchets des chantiers  et l'empreinte écologique de la construction.",
            part3: 'Grâce à notre solution, vous pouvez vendre ou céder : ',
            part4: 'des matériaux de déconstruction, des surplus de chantier, des fins de stock',
            part5: ', pour qu’ils deviennent les ressources de projets ambitieux.',
          },
          solution1: {
            title: 'Vous pouvez proposer vos ressources',
            description:
              'Vous évitez les coûts d’enlèvement et de traitement de vos matériaux lors de démolition ou rénovation et vous participez ainsi à un approvisionnement durable en développant une économie circulaire.',
          },
          solution2: {
            title: 'Vous pouvez indiquer vos besoins',
            description:
              'Vous bénéficierez d’un approvisionnement local, à moindre impact carbone. ',
          },
        },
        economy: {
          title: "Projet LIFE Waste2Build : Mise en place d'une économie circulaire dans le BTP",
          part1: 'Cette plateforme a été développée dans le cadre du ',
          lw2b: 'projet Life Waste2Build',
          'part1&5': ' pour ',
          part2:
            'encourager l’emploi de matériaux de seconde vie dans la construction, la rénovation ou l’aménagement pour construire dans une démarche d’économie circulaire. ',
          part3:
            'Dans le cadre de la première phase du projet Life WasteBuild, la plateforme est proposée ',
          part4: 'gratuitement à titre expérimental.',
          part5:
            "En développant le réemploi dans le secteur du BTP, le projet Life Waste2Build participe à la mise en place d'une économie circulaire.",
          card1: 'Chantiers exemplaires',
          card2: 'De déchets revalorisés',
          card3: 'De réemploi',
          card4: 'Guichet territorial avec des outils dédiés',
          card5: 'La signature d’une charte économie circulaire BTP',
          card6: 'Des formations pour développer la filière du BTP circulaire',
          card7: 'Une boîte à outils',
        },
        coordinated: {
          title: 'Le projet est coordonné par Toulouse Métropole',
        },
        partners: {
          title: 'Des partenaires bénéficiaires',
        },
        admins: {
          title: 'Des administrateurs fonctionnels',
        },
        signatories: {
          title: 'Les signataires de la charte économie circulaire dans le BTP',
        },
      },
      cgu: {
        goBack: 'Retour',
        title: "Conditions générales d'utilisation",
        paragraph1: {
          title: 'Conditions Générales d’Utilisation',
          description: {
            part1: 'En naviguant sur le site ',
            part2:
              ' vous reconnaissez avoir pris connaissance de la présente notice légale et vous vous engagez à la respecter.\nL’utilisation des services du site ',
            part3:
              ' vaut acceptation par l’utilisateur de l’ensemble des conditions d’utilisations énoncées ci-après. Si vous refusez ces conditions, vous devez quitter le site.\nPour information, le traitement des données personnelles de la solution ',
            part4:
              ' est fixé par la page dédiée disponible sur le site : Données personnelles – Toulouse Métropole.',
          },
        },
        paragraph2: {
          title: 'Disponibilité',
          description:
            'Le service est disponible 7 jours sur 7 et 24H sur 24 (sous réserve d’incident). Il est à noter que le service peut être momentanément interrompu à l’occasion de maintenances ou du fait de pointes de trafic. Toulouse Métropole n’assumera aucune responsabilité pour tout défaut d’accès au site causé par un logiciel utilisé par l’utilisateur lorsqu’il entre des informations sur le site.\n\nToulouse Métropole se réserve la faculté de faire évoluer, de modifier ou de suspendre, sans préavis, la plateforme pour des raisons de maintenance, pointe de trafic pour tout autre motif jugé d’intérêt général.',
        },
        paragraph3: {
          title: 'Responsabilité',
          description: {
            part1: 'L’activité de ',
            part2:
              ' se limite à mettre en relation le Fournisseur de ressources et l’Acquéreur de ressources, sans intervenir en tant que partie dans la relation pouvant résulter de cette mise en relation. Par suite, ',
            part3:
              ' ne saurait voir sa responsabilité engagée au titre des relations, contractuelles ou non, résultant du rapprochement entre le Fournisseur de ressources et l’Acquéreur de ressources.\n\nChaque personne physique ou morale est responsable du contenu publié par ses soins. Il est tenu de respecter le périmètre de la présente consultation et de tenir des propos qui ne sont pas de nature à contrevenir aux bonnes mœurs, à l’ordre public ou aux dispositions légales en vigueur.\n\nLa plateforme ',
            part4:
              ' ne vous garantit pas l’existence ou la disponibilité de toute offre mentionnée dans le présent site Web.\n\nLa plateforme web ',
            part5:
              ' ne garantit pas les termes définitifs, ni la durée d’une offre obtenue par le biais du présent site Web.\n\nEn utilisant le présent site Web, vous assumez les risques relatifs à la complétude, exactitude, adéquation ou actualité de l’information.\n\nEn cas de doute quant à la conformité d’une information renseignée par un utilisateur, ',
            part6:
              ' se réserve le droit de retirer immédiatement cette information sans faire l’objet d’un quelconque dédommagement à l’utilisateur. Celui-ci devra apporter les preuves que l’information est licite pour se voir réactivés ses droits de publication.',
          },
        },
        paragraph4: {
          title: 'Engagements en tant qu’utilisateur',
          description: {
            part1: 'En tant qu’utilisateur, en utilisant le site ',
            part2:
              ' vous vous engagez :\n\n– à ne pas communiquer vos codes d’accès et mots de passe à un tiers,\n– à ne pas collecter et stocker des données personnelles afférentes aux autres utilisateurs,\n– à utiliser les services de la plateforme conformément à la réglementation française et aux dispositions européennes et en particulier à ne pas les utiliser pour afficher tout contenu contraire à la législation sur les bonnes mœurs et l’ordre public. ',
            part3:
              ' se réserve le droit de supprimer sans avertissement toute information contraire aux textes en vigueur,\n– à ne pas harceler de quelque manière que ce soit un autre utilisateur,\n– sur la véracité des informations que vous saisissez,\n– sur la mise à jour éventuelle de votre profil.',
          },
        },
      },
      rgpd: {
        goBack: 'Retour',
        title: 'Règlement général sur la protection des données',
        paragraph1: {
          title: 'Règlement général sur la protection des données',
          description: {
            part1:
              "Les données recueillies font l’objet d’un traitement de données à caractère personnel dans le but de favoriser le développement de circuits courts et de boucles d'économie circulaires. Toulouse Métropole et l’association SYNETHIC sont responsables conjoints du traitement. Les agents habilités, les prestataires bureaux d’études et le prestataire éditeur Raedificare sont destinataires des données 3 ans ou jusqu’à clôturedu compte utilisateur. Conformément à la législation relative à la protection des données personnelles, vous bénéficiez d'un droit d'accès, de modification des données qui vous concernent. \n\nVous pouvez exercer ces droits en vous adressant par voie postale à ",
            part2:
              'Mission Économie circulaire - Direction de l’Action Économique – Toulouse Métropole 6 rue René Leduc Toulouse Cedex 5 ',
            part3: 'ou par email à ',
            part4: 'contactsentreprises.dae@toulousemetropole.fr',
            part5:
              '. \n\nVous pouvez vous opposer au traitement des données vous concernant. La base légale de cetraitement est le consentement.',
          },
        },
      },
    },
  },
}

export default tmConfig
