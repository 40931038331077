import { Box, Checkbox, FormControlLabel, styled, InputLabel, FormHelperText } from '@mui/material'
import { CheckboxProps } from '../../../models/props.models'
import { useCallback } from 'react'
const CheckboxContainer = styled(FormControlLabel)({
  alignItems: 'flex-start',
  display: 'flex',
  '& > span:first-of-type': {
    marginTop: '-1px',
  },
  '& .MuiTypography-root:first-of-type': {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
})

const CheckboxInput: React.FC<CheckboxProps> = ({
  label,
  value,
  disabled,
  error,
  falseValue,
  onChange,
  readOnly,
  required,
  children,
}) => {
  const click = useCallback(() => {
    if (!readOnly) {
      if (falseValue) {
        onChange?.(value === true ? false : value === false ? undefined : true)
      } else {
        onChange?.(!value)
      }
    }
  }, [value, falseValue, readOnly, onChange])
  return (
    <Box pl="10px" display="flex" flexDirection="column">
      <CheckboxContainer
        control={
          <Checkbox color="primary" size="small" indeterminate={falseValue && value === false} />
        }
        labelPlacement="end"
        onChange={click}
        checked={!!value ?? false}
        disabled={disabled}
        label={
          <Box ml="8px" onClick={click}>
            {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
            {!!children && children}
          </Box>
        }
      />
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default CheckboxInput
