import { Outlet, useParams } from 'react-router-dom'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import { Mode, Route } from '../../../models/commons.models'
import { isNeeds } from '../../../models/catalogs.models'
import { useEffect, useRef } from 'react'
import { catalogsQuery, catalogsService } from '../../../store/catalogs'
import { sessionQuery, sessionService } from '../../../store/session'
import { useObservable } from '@ngneat/react-rxjs'

import LoaderOverlay from '../../../components/layout/LoaderOverlay.layout'
import CatalogHeader from '../../../components/catalog/Header.catalog'
import Stack from '../../../components/common/Stack.common'

const PublicCatalogDetails = () => {
  useCheckRoute('PublicCatalogDetails', [Mode.front, Mode.storeFront])

  const showPublicNeeds = useRef(sessionService.showPublicNeeds())
  const [useImperials] = useObservable(sessionQuery.useImperials)

  const show = useSnackbar()
  const { goTo } = useRoute()
  const { catalogId } = useParams()

  const [catalog] = useObservable(catalogsQuery.catalog)
  const [loading] = useObservable(catalogsQuery.detailLoading)
  const [isPublic] = useObservable(catalogsQuery.isPublic)

  useEffect(() => {
    const getCatalog = async () => {
      try {
        await catalogsService.initDetail(catalogId as string, true)
      } catch (err: any) {
        show(err)
      }
    }
    if (catalogId && (!catalog || catalog._id !== catalogId || !isPublic)) {
      getCatalog()
    }
  }, [show, catalogId, catalog, isPublic])

  if (loading || !catalog?._id || catalog._id !== catalogId) return <LoaderOverlay />

  return (
    <>
      {loading && <LoaderOverlay />}
      <CatalogHeader
        catalog={catalog}
        isPublic
        onAction={(action: string) => {
          switch (action) {
            case 'goBack':
              if (showPublicNeeds.current) {
                goTo({
                  route: isNeeds(catalog.type)
                    ? Route.publicCatalogsNeeds
                    : Route.publicCatalogsResources,
                })
              } else {
                goTo({ route: Route.publicCatalogs })
              }
              break
            default:
          }
        }}
      />
      <Stack height="100%" overflow="hidden" flexGrow={1}>
        <Outlet context={{ catalog, isPublic, useImperials }} />
      </Stack>
    </>
  )
}
export default PublicCatalogDetails
