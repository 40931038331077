export const createTypography = (colors: any) => ({
  MuiTypography: {
    h2: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    colorPrimary: {
      color: colors.mainText,
    },
    colorSecondary: {
      color: colors.secondaryText,
    },
  },
})
