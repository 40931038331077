export const DEFAULT_UI = {
  appBarSize: 80,
  catalogHeaderSize: 200,
  planAsideSize: 300,
  leftBarSize: 260,
  needCardSize: 300,
  buyResourceCardSize: 365,
  resourceCardSize: 300,

  // table
  paginationLimit: 50,
  rowHeight: 75,
  headerHeight: 30,

  //plan
  maxViewBox: 1000,
  defaultScale: 1,
  pinchBound: { min: 0.01, max: 100 },
  zoomBound: { min: 0.8, max: 20 },
  doubleClickLatency: 200,
  pixelWall: 4,
  selectedWallRation: 1.5,
  pixelCorner: 6,
  selectedCornerRation: 1.5,
  pixelLocatedMaterial: 35,

  transparentHeader: true,

  mapZoom: 6,
}

export type Ui = typeof DEFAULT_UI & { mapCenter?: [number, number] }
