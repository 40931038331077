import { useCallback, useEffect, useState } from 'react'
import { useObservable } from '@ngneat/react-rxjs'
import { useTranslation } from 'react-i18next'
import { Grid, alpha, Box, Button, Card, styled, Typography } from '@mui/material'
import {
  ShoppingBasket as ShoppingBasketIcon,
  Delete as DeleteIcon,
  Scale as ScaleIcon,
} from '@mui/icons-material'

import { cartsService, cartsQuery } from '../store/carts'
import { ordersService } from '../store/orders'
import { materialsService } from '../store/materials'
import { sessionQuery, sessionService } from '../store/session'

import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import useSnackbar from '../hooks/useSnackbar.hooks'
import useModal from '../hooks/useModal.hooks'
import { Mode, Route, Currency } from '../models/commons.models'
import { Material, TermsOfSale } from '../models/materials.models'
import {
  Product,
  ApiProduct,
  convertOrder2Meters,
  convertOrder2Imperials,
} from '../models/orders.models'
import { Catalog } from '../models/catalogs.models'
import LoaderOverlay from '../components/layout/LoaderOverlay.layout'
import OrderListProduct from '../components/order/ListProduct.order'
import ModalDetails from '../components/material/ModalDetails.material'

const CardTitle = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginBottom: '28px',
})

const TotalHTRow = styled(Box)(({ theme }) => ({
  padding: '16px 0',
  paddingTop: '0px',
  fontSize: '.6875rem',
  letterSpacing: -0.14,
  fontWeight: 300,
  borderBottom: `1px solid ${theme.palette.menuBorder}`,
}))
const TaxRow = styled(Box)({
  padding: '16px 0',
  fontSize: '.6875rem',
  letterSpacing: -0.14,
  fontWeight: 300,
})
const TotalTTCRow = styled(Box)(({ theme }) => ({
  padding: '16px 20px',
  fontSize: '.75rem',
  letterSpacing: -0.15,
  fontWeight: 500,
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  textAlign: 'center',
}))

const Title = styled(Typography)({
  marginBottom: '10px',
})
const Subtitle = styled(Typography)({
  textAlign: 'center',
  marginBottom: '38px',
})

const ActionButton = styled(Button)({
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: '0 15px',
  '& .MuiButton-startIcon': {
    marginRight: '7px',
  },
})
export const TableTitle = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
})
export const TableModality = styled(Typography)({
  fontSize: '0.875',
})

const ValidateButton = styled(Button)({ padding: '10px 55px' })

const TotalWeightLabel = styled(Typography)({
  marginLeft: '5px',
  fontSize: '0.75rem',
  fontWeight: 400,
})

type CatalogProducts = { catalog: Catalog; products: Product[] }
const Cart = () => {
  useCheckRoute('Cart', [Mode.front, Mode.storeFront])
  const [modal, setModal] = useModal<string>()
  const { t } = useTranslation()
  const { goTo } = useRoute()
  const [defaultValue, setDefaultValue] = useState<Material | undefined>(undefined)
  const show = useSnackbar()
  const [materials, setMaterials] = useState<Material[]>([])
  const [catalogProducts, setCatalogProducts] = useState<CatalogProducts[]>([])
  const [loading, setLoading] = useState(false)
  const [tax, setTax] = useState<number | undefined>(undefined)
  const [useImperials] = useObservable(sessionQuery.useImperials)

  const updateQuantity = useCallback(
    (product: Product) =>
      cartsService.updateQuantity(
        product.material._id,
        useImperials
          ? convertOrder2Meters({ products: [product] }).products[0].quantity
          : product.quantity,
      ),
    [useImperials],
  )
  const openProduct = useCallback(
    (product: Product) => setModal(`${product.material._id}`),
    [setModal],
  )

  useEffect(() => {
    const getTax = async () => {
      let currentTax: number | undefined

      for (let i = 0; i < catalogProducts.length; i++) {
        if (catalogProducts[i].catalog.organization?.location?.countryCode) {
          if (
            i < catalogProducts.length - 1 &&
            catalogProducts[i].catalog.currency !== catalogProducts[i + 1].catalog.currency
          ) {
            currentTax = undefined
            break
          }

          let catalogTax = (
            await sessionService.getCountry(
              catalogProducts[i].catalog.organization?.location?.countryCode,
            )
          ).tax as number | undefined

          if (i === 0) {
            currentTax = catalogTax
          } else if (catalogTax !== currentTax) {
            currentTax = undefined
            break
          }
        }
      }
      setTax(currentTax)
    }
    getTax()
  }, [catalogProducts])

  useEffect(() => {
    if (modal && !defaultValue) {
      const getMaterial = async () => {
        setLoading(true)
        try {
          const material = await materials.find((material: Material) => material._id === modal)
          setDefaultValue(material)
        } catch (err: any) {
          setModal('')
          show(err)
        }
        setLoading(false)
      }
      getMaterial()
    } else if (!modal && defaultValue) {
      setDefaultValue(undefined)
    }
  }, [materials, modal, defaultValue, show, setModal])

  const [user] = useObservable(sessionQuery.user)
  const [cart] = useObservable(cartsQuery.cart)
  useEffect(() => {
    if (cart.length > 0) {
      const materialsToLoad = cart.filter(
        (product: ApiProduct) =>
          !materials.find((material: Material) => product.material === material._id),
      )
      if (materialsToLoad.length > 0) {
        const loadMaterials = async () => {
          setLoading(true)
          const loaded: Material[] = []
          for (let i = 0; i < materialsToLoad.length; i++) {
            try {
              loaded.push(await materialsService.getMaterial(materialsToLoad[i].material, true))
            } catch (err: any) {
              cartsService.updateQuantity(materialsToLoad[i].material, 0)
              // show(err)
            }
          }
          setMaterials((materials) => materials.concat(loaded))

          setLoading(false)
        }
        loadMaterials()
      }
    }
  }, [materials, show, cart])

  useEffect(() => {
    if (!loading) {
      setCatalogProducts(
        cart
          ?.reduce((catalogProducts: CatalogProducts[], product: ApiProduct): CatalogProducts[] => {
            let material = materials.find((material: Material) => material._id === product.material)
            if (material && product.quantity > 0) {
              let catalogProduct = catalogProducts.find(
                (catalogProduct: CatalogProducts) =>
                  catalogProduct.catalog._id === material?.catalog._id,
              )

              if (catalogProduct) {
                catalogProduct.products.push({ material, quantity: product.quantity })
              } else {
                catalogProducts.push({
                  catalog: material.catalog as Catalog,
                  products: [{ material, quantity: product.quantity }],
                })
              }
            }
            return catalogProducts
          }, [] as CatalogProducts[])
          .map((catalogProduct: CatalogProducts) =>
            useImperials ? convertOrder2Imperials(catalogProduct) : catalogProduct,
          ),
      )
    }
  }, [materials, cart, loading, useImperials])

  const handleValidateBasket = async () => {
    setLoading(true)
    try {
      if (!user) {
        show(t('sessions:actions.needLog.order'), 'warning')
      } else {
        await ordersService.order(cart)
        show(t('orders:pages.cart.orderSuccess'))
        cartsService.emptyCart()
        goTo({ route: Route.myOrders })
      }
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  const totalPerCurrency = catalogProducts.reduce(
    (totalPerCurrency: Record<Currency, number>, catalogProduct: CatalogProducts) => {
      let catalogTotal = catalogProduct.products.reduce(
        (catalogTotal: number, product) =>
          catalogTotal + (product.material.price ? product.material.price * product.quantity : 0),
        0,
      )

      if (totalPerCurrency[catalogProduct.catalog.currency]) {
        totalPerCurrency[catalogProduct.catalog.currency] += catalogTotal
      } else {
        totalPerCurrency[catalogProduct.catalog.currency] = catalogTotal
      }
      return totalPerCurrency
    },
    {} as Record<Currency, number>,
  )

  const currencyUsed: Currency[] = Object.keys(totalPerCurrency) as Currency[]
  const totalHT = currencyUsed
    .map(
      (currency: Currency) =>
        `${totalPerCurrency[currency].toFixed(2)}${t(`global:currency.${currency}`)}`,
    )
    .join(', ')
  const totalTTC =
    tax && currencyUsed.length === 1
      ? `${(totalPerCurrency[currencyUsed[0]] * (1 + tax)).toFixed(2)}${t(
          `global:currency.${currencyUsed[0]}`,
        )}`
      : '-'

  const totalWeight: number | undefined = catalogProducts.reduce(
    (total: number | undefined, catalogProduct: CatalogProducts) => {
      const catalogTotal: number | undefined = catalogProduct.products.reduce(
        (catalogTotal: number | undefined, product) =>
          !product.material.unitWeight || catalogTotal === undefined
            ? undefined
            : catalogTotal + product.material.unitWeight * product.quantity,
        0,
      )
      return catalogTotal !== undefined && total !== undefined ? catalogTotal + total : undefined
    },
    0,
  )
  const hasWrongQuantity = catalogProducts.reduce(
    (hasWrongQuantity: boolean, catalogProduct: CatalogProducts) => {
      return (
        hasWrongQuantity ||
        catalogProduct.products.some(
          (product: Product) =>
            product.quantity < product.material.minQuantity ||
            product.quantity > product.material.currentQty,
        )
      )
    },
    false,
  )
  const hasUndefinedPrice = catalogProducts.reduce(
    (hasWrongQuantity: boolean, catalogProduct: CatalogProducts) => {
      return (
        hasWrongQuantity ||
        catalogProduct.products.some(
          (product: Product) => product.material.termsOfSale === TermsOfSale.notDefined,
        )
      )
    },
    false,
  )

  return (
    <Grid padding="15px" container spacing={2} position="relative">
      {loading && <LoaderOverlay />}

      <Grid item display="flex" justifyContent="space-between" alignItems="center" xs={12}>
        <Typography variant="h2">{t('orders:pages.cart.title')}</Typography>
        {!!cart.length && (
          <ActionButton onClick={cartsService.emptyCart} color="primary" startIcon={<DeleteIcon />}>
            {t('orders:pages.cart.empty')}
          </ActionButton>
        )}
      </Grid>

      {cart.length === 0 && (
        <Grid item justifyContent="center" alignItems="center" xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt="100px">
            <Box mb="10px">
              <ShoppingBasketIcon sx={{ fontSize: '43px' }} />
            </Box>
            <Title variant="h3">{t('orders:pages.cart.emptyTitle')}</Title>
            <Subtitle>{t('orders:pages.cart.emptyDescription')}</Subtitle>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => goTo({ route: Route.publicMaterials })}>
              {t('orders:pages.cart.emptyButton')}
            </Button>
          </Box>
        </Grid>
      )}
      {cart.length > 0 && (
        <>
          <Grid item justifyContent="center" alignItems="flex-start" xs={12} lg={8}>
            {catalogProducts.map((catalogProduct: CatalogProducts) => (
              <Card
                key={catalogProduct.catalog._id}
                sx={{ padding: '15px 15px 0 15px', marginBottom: '15px' }}>
                <TableTitle>{catalogProduct.catalog.name}</TableTitle>
                {catalogProduct.catalog.retrieval && (
                  <TableModality>
                    <>
                      {t('orders:pages.cart.retrieval')}
                      {catalogProduct.catalog.retrieval.location.fullAddress?.replace(', ', '\n') ||
                        ''}
                      {catalogProduct.catalog.retrieval.startDate ||
                      catalogProduct.catalog.retrieval.startDate
                        ? t('orders:pages.cart.fromTo', {
                            from: new Date(
                              catalogProduct.catalog.retrieval.startDate || 'invalid date',
                            ),
                            to: new Date(
                              catalogProduct.catalog.retrieval.endDate || 'invalid date',
                            ),
                          })
                        : ''}
                    </>
                  </TableModality>
                )}

                {catalogProduct.catalog.retrieval?.retrievalModality && (
                  <TableModality>
                    {t(
                      `global:retrievalModality.${catalogProduct.catalog.retrieval.retrievalModality}`,
                    )}
                  </TableModality>
                )}
                {catalogProduct.catalog.retrievalInformation && (
                  <TableModality>{catalogProduct.catalog.retrievalInformation}</TableModality>
                )}

                <OrderListProduct
                  values={catalogProduct.products}
                  onValueClick={openProduct}
                  canUpdate
                  onProductChange={updateQuantity}
                />
              </Card>
            ))}

            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <ScaleIcon />
              <TotalWeightLabel>
                {t('orders:pages.cart.totalWeight')} :&nbsp;
                <b>
                  {totalWeight === undefined
                    ? '-'
                    : totalWeight > 999
                    ? t('materials:attributes.weight.tonne', { value: totalWeight })
                    : t('materials:attributes.weight.kg', { value: totalWeight })}
                </b>
              </TotalWeightLabel>
            </Box>
          </Grid>
          <Grid item justifyContent="center" alignItems="flex-start" xs={12} lg={4}>
            <Card sx={{ padding: '15px', maxWidth: '500px', margin: 'auto' }}>
              <CardTitle>{t('orders:pages.cart.summary')}</CardTitle>
              {tax ? (
                <TotalHTRow display="flex" justifyContent="space-between">
                  <span>{t('orders:pages.cart.totalHT')}</span>
                  <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalHT}</span>
                </TotalHTRow>
              ) : (
                <TotalTTCRow display="flex" justifyContent="space-between">
                  <span>{t('orders:pages.cart.totalHT')}</span>
                  <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalHT}</span>
                </TotalTTCRow>
              )}
              {tax && (
                <>
                  <TaxRow display="flex" justifyContent="space-between">
                    <span>{t('orders:pages.cart.tax')}</span>
                    <span>
                      {tax ? t('global:format.percent', { value: (tax * 100).toFixed(2) }) : '-'}
                    </span>
                  </TaxRow>
                  <TotalTTCRow display="flex" justifyContent="space-between">
                    <span>{t('orders:pages.cart.totalTTC')}</span>
                    <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalTTC}</span>
                  </TotalTTCRow>
                </>
              )}
              <Typography mt="15px" fontSize=".6875rem">
                {t('orders:pages.cart.priceChange')}
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center" mt="37px">
                <ValidateButton
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={loading || hasWrongQuantity}
                  onClick={handleValidateBasket}>
                  {t('orders:pages.cart.validate')}
                </ValidateButton>
                {hasWrongQuantity && (
                  <Box mt="15px">
                    <Typography variant="h3" color="error">
                      {t('orders:pages.cart.quantityError')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        </>
      )}

      {modal && defaultValue && (
        <ModalDetails
          useImperials={useImperials}
          isPublic
          material={defaultValue as Material}
          onClose={() => setModal('')}
        />
      )}
    </Grid>
  )
}
export default Cart
